import React, { Component, Fragment } from 'react'
import { observer, inject } from 'mobx-react'
import { Card, Row, Col, Input, Button, Modal } from 'antd'
import QRCode from 'qrcode.react'

import './index.less'

import icon_l from '../../../images/pop-0.png'
import icon_r from '../../../images/pop-1.png'
import icon_move from '../../../images/move.png'
import { QR } from '../../../config'

@inject('userStore', 'assetsStore', 'verifyStore')
@observer
class DetailMove extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isModalShow: false,
      assetId: '',
      param_address: '',
      flag_address: false
    }
  }

  componentDidMount() {
    let assetId = this.props.match.params.id
    const currentCardAddress = localStorage.getItem('currentCardAddress')
    this.props.assetsStore.getMoveDetail(currentCardAddress, assetId)
    this.setState({
      assetId: assetId
    })
  }

  renderTipBar = () => {
    return (
      <div className='tip-bar'>
        <Row>
          <Col span={21} offset={3}>
            温馨提示：资产移动不可撤销，请谨慎操作。
          </Col>
        </Row>
      </div>
    )
  }

  setParamAddress = e => {
    let temp = false
    let value = e.target.value
    let regAddress = /^[0-9a-zA-Z]+$/
    if (!regAddress.test(value) || value.indexOf('0x') !== 0 || value.length !== 42) {
      temp = true
    }
    this.setState({
      param_address: value,
      flag_address: temp
    })
  }

  check = () => {
    if (!this.state.param_address) {
      this.setState({
        flag_address: true
      })
      return
    }
    if (this.state.flag_address) {
      return
    }
    this.handleShow()
  }

  renderForm = () => {
    const { moveDetailInfo } = this.props.assetsStore
    return (
      <Fragment>
        <div className='form'>
          <Row>
            <Col span={3} offset={3}>
              <div className='label'>当前管理员地址</div>
            </Col>
            <Col span={15}>
              <span className='value'>{moveDetailInfo.managerAddress}</span>
            </Col>
          </Row>
          <Row>
            <Col span={3} offset={3} />
            <Col span={15}>
              <span className='value'>管理权转移</span>
              <img src={icon_move} alt='icon_move' />
            </Col>
          </Row>
          <Row>
            <Col span={3} offset={3}>
              <div className='label'>新管理员地址</div>
            </Col>
            <Col span={15}>
              <div className='input-box'>
                <Input
                  placeholder='请输入新管理员地址'
                  onChange={e => this.setParamAddress(e)}
                  value={this.state.param_address}
                />
              </div>
              <div className='tip'>{this.state.flag_address && '请输入正确的地址'}</div>
            </Col>
          </Row>
          <div className='btn-box'>
            <Button size='large' disabled={false} onClick={this.check}>
              下一步
            </Button>
          </div>
        </div>
      </Fragment>
    )
  }

  handleShow = () => {
    this.setState({
      isModalShow: true
    })
  }

  handleOk = () => {
    this.setState({
      isModalShow: false
    })
    this.props.assetsStore.doStartMove(this.state.assetId)
    this.props.assetsStore.showMoveModal()
  }

  handleCancel = () => {
    this.setState({
      isModalShow: false
    })
  }

  renderModal = () => {
    const { moveDetailInfo } = this.props.assetsStore
    return (
      <Modal
        title='请您确认资产管理权转移信息'
        visible={this.state.isModalShow}
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        footer={null}
        closable={false}
      >
        <div className='modal-box'>
          <span className='label'>当前管理员地址</span>
          <span className='value'>{moveDetailInfo.managerAddress}</span>
          <img src={icon_move} alt='icon_move' />
          <span className='label'>当前管理员地址</span>
          <span className='value'>{this.state.param_address}</span>
        </div>
        <div className='btn-box'>
          <Row>
            <Col span={11}>
              <Button className='cancel' onClick={this.handleCancel}>
                取消
              </Button>
            </Col>
            <Col span={11}>
              <Button className='confirm' onClick={this.handleOk}>
                确认
              </Button>
            </Col>
          </Row>
        </div>
      </Modal>
    )
  }

  renderTxModal = () => {
    const { moveModalStatus, hideMoveModal, moveScanStatus, moveTxId } = this.props.assetsStore
    const { assetId, param_address } = this.state
    let qrInfo = QR.move(moveTxId, assetId, param_address)
    return (
      <Modal
        title='所有权转移签名授权'
        visible={moveModalStatus}
        onOk={hideMoveModal}
        onCancel={hideMoveModal}
        footer={null}
        closable={false}
      >
        <Row>
          <Col className='top' span={10} offset={7}>
            <img src={icon_l} alt='' />
            <div className='dashed' />
            <img src={icon_r} alt='' />
          </Col>
        </Row>
        <Row>
          <Col className='cen'>请打开鲸矿APP扫码</Col>
        </Row>
        <Row>
          <Col className='btm' span={10} offset={7}>
            <div className='box'>
              {moveScanStatus === 1 ? (
                <div className='mask'>
                  <span className='pending'>等待中...</span>
                </div>
              ) : null}
              <div className='qrcode-box'>
                <QRCode value={qrInfo} size={180} renderAs={'svg'} />
              </div>
            </div>
          </Col>
        </Row>
      </Modal>
    )
  }

  render() {
    return (
      <div className='move'>
        <Card className='contain'>
          <h1>资产详情/资产管理权迁移</h1>
          {this.renderTipBar()}
          {this.renderForm()}
          {this.renderModal()}
        </Card>
        {this.renderTxModal()}
      </div>
    )
  }
}

export default DetailMove
