import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import { Card, Icon, message } from 'antd'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import './index.less'

@inject('userStore')
@observer
class Manage extends Component {
  async componentDidMount() {}

  renderList = () => {
    const { userStore } = this.props
    const { cardList } = userStore
    let list = []
    for (let item of cardList) {
      list.push(
        <Card key={item.id} className='card'>
          <div className='nickname'>
            <span>{`鲸卡昵称：${item.nickname}`}</span>
          </div>
          <div className='address'>
            <span>{item.addressString}</span>
            <CopyToClipboard
              text={item.addressString}
              onCopy={() => {
                message.success('已复制')
              }}
            >
              <Icon type='copy' />
            </CopyToClipboard>
          </div>
        </Card>
      )
    }
    return list
  }

  render() {
    return (
      <div className='manage'>
        <Card className='minHeight'>
          <h1>账户管理</h1>
          <div className='tip'>友情提示：如果您需要添加新的鲸卡，请前往鲸矿app创建。</div>
          <div className='card-box'>{this.renderList()}</div>
        </Card>
      </div>
    )
  }
}

export default Manage
