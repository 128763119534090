import { ApolloClient } from 'apollo-client'
import { HttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { onError } from 'apollo-link-error'
import { ApolloLink } from 'apollo-link'
import { RetryLink } from 'apollo-link-retry'
import { WebSocketLink } from 'apollo-link-ws'
import { createUploadLink } from 'apollo-upload-client'
import { getMainDefinition } from 'apollo-utilities'
import gql from 'graphql-tag'

import { message as alert } from 'antd'

import { config } from '../config'
import { errMsg } from './errors'

let checkIsRedirectToLogin = message => {
  if (message === 'credentials error' || message === 'Token is expired') {
    localStorage.clear()
    window.location.replace(`${config.host_route}/#/login`)
  }
}

let client = () => {
  return new ApolloClient({
    link: ApolloLink.from([
      onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
          graphQLErrors.map(({ message, locations, path }) => {
            console.log(
              `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
            )
            alert.error(errMsg(message))
            checkIsRedirectToLogin(message)
            return graphQLErrors
          })
        }
        if (networkError) {
          console.log(`[Network error]: ${networkError}`)
          alert.error('连接失败，请检查网络')
          localStorage.clear()
          window.location.replace(`${config.host_route}/#/login`)
        }
      }),
      new HttpLink({
        uri: config.host,
        headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
        credentials: 'same-origin',
      }),
    ]),
    cache: new InMemoryCache(),
  })
}

let subscriptionClient = () => {
  return new ApolloClient({
    link: new RetryLink().split(
      sys => {
        let { operation } = getMainDefinition(sys.query)
        return operation === 'subscription'
      },
      new WebSocketLink({
        uri: config.ws_host,
        options: {
          reconnect: true,
        },
      }),
      new HttpLink({
        uri: config.host,
        headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
        credentials: 'same-origin',
      })
    ),
    cache: new InMemoryCache(),
  })
}

let uploadClient = () => {
  return new ApolloClient({
    link: ApolloLink.from([
      onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors)
          graphQLErrors.map(({ message, locations, path }) =>
            console.log(
              `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
            )
          )
        if (networkError) console.log(`[Network error]: ${networkError}`)
      }),
      createUploadLink({
        uri: config.host_upload,
        headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
        credentials: 'same-origin',
      }),
    ]),
    cache: new InMemoryCache(),
  })
}

let checkExpiredDate = async () => {
  const EXPIRED_DATE = localStorage.getItem('expiredDate')
  if (EXPIRED_DATE) {
    const NOW = Number(Date.parse(new Date()) / 1000)
    if (EXPIRED_DATE < NOW) {
      await client()
        .mutate({
          mutation: gql`
            mutation refreshToken($refreshToken: String!) {
              refreshToken(refreshToken: $refreshToken) {
                token
                refreshToken
                expiredDate
              }
            }
          `,
          variables: {
            refreshToken: localStorage.getItem('refreshToken'),
          },
        })
        .then(res => {
          localStorage.setItem('token', res.data.refreshToken.token)
          localStorage.setItem(
            'refreshToken',
            res.data.refreshToken.refreshToken
          )
          localStorage.setItem('expiredDate', res.data.refreshToken.expiredDate)
        })
    }
  }
}

let query = async (body, variables) => {
  await checkExpiredDate()
  return client()
    .query({
      query: gql`
        ${body}
      `,
      variables: variables,
    })
    .then(res => {
      return res.data
    })
    .catch(err => {
      return false
    })
}

let mutation = async (body, variables) => {
  await checkExpiredDate()
  return client()
    .mutate({
      mutation: gql`
        ${body}
      `,
      variables: variables,
    })
    .then(res => {
      return res.data
    })
    .catch(err => {
      return false
    })
}

let subscription = async (body, variables) => {
  await checkExpiredDate()
  return subscriptionClient().subscribe({
    query: gql`
      ${body}
    `,
    variables: variables,
  })
}

let upload = async (body, variables) => {
  await checkExpiredDate()
  return uploadClient()
    .mutate({
      mutation: gql`
        ${body}
      `,
      variables: variables,
    })
    .then(res => {
      return res.data
    })
}

export { query, mutation, subscription, upload }
