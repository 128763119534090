import React, { Component } from 'react'
import './document.less'

class AboutUs extends Component {
  render() {
    return (
      <div className='document-wrp'>
        <h3>关于我们</h3>
        <p>上海域乎信息技术有限公司是融合了物联网和区块链为核心技术的数字资产技术服务平台，是国内最早接触并探索区块链技术和产业应用的公司之一。域乎融合区块链、物联网、人工智能、大数据等技术为企业提供供应链全透明追溯系统。</p>
        <p>公司致力于使用创新技术解决数字资产世界存在的信任、成本和效率问题，帮助企业快速打造核心价值，建立竞争优势，共同构建全球信任价值网络。</p>
        <p>2018年夏，公司完成首轮千万级融资，由汉理资本旗下“凌志汉理创业投资”（五号基金）和“汉理杭州前秀”（六号基金）共同参投。</p>
        <p>2018年12月，“虎鲸链”通过工信部电标院权威测评并正式上线。</p>
      </div>
    )
  }
}

export default AboutUs
