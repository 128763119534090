import React, { Component, Fragment } from 'react'
import { observer, inject } from 'mobx-react'
import {
  Card,
  Row,
  Col,
  Select,
  Input,
  Button,
  Icon,
  Table,
  Popover,
  Modal,
} from 'antd'

import './index.less'
import { config, OriginToken } from '../../../config'

const { Option } = Select
const { confirm } = Modal

@inject('dropStore')
@observer
class DropDetail extends Component {
  constructor(props) {
    super(props)
    this.state = {
      param_id: '',
      task_skip: 0,
      task_limit: 10,
      task_state: '',
      task_address: '',
      col_addr: [
        {
          title: '编号',
          dataIndex: 'id',
          width: '20%',
          render: text => (
            <Popover content={text}>
              <span className="eclipse">{text}</span>
            </Popover>
          ),
        },
        {
          title: '',
          dataIndex: '',
          width: '5%',
        },
        {
          title: '地址',
          dataIndex: 'targetAddress',
          width: '30%',
        },
        {
          title: `持有数量`,
          dataIndex: 'value',
          width: '25%',
        },
        {
          title: '状态',
          dataIndex: 'state',
          width: '10%',
          render: text => (
            <Fragment>
              {text === 0 && <span className="pending">未开始</span>}
              {text === 1 && <span className="pending">进行中</span>}
              {text === 2 && <span className="success">成功</span>}
              {text === 3 && <span className="failed">失败</span>}
              {text === 4 && <span className="success">重新空投成功</span>}
            </Fragment>
          ),
        },
        {
          title: '操作',
          dataIndex: 'txhash',
          width: '10%',
          render: text => (
            <span
              onClick={() => {
                window.open(`${config.explorer_txs}/${text}`)
              }}
            >
              详情
            </span>
          ),
        },
      ],
    }
  }

  componentDidMount() {
    const { dropStore } = this.props
    const { getAirdropDetailInfo, getDropDetailAddressList } = dropStore
    const { task_skip, task_limit, task_state, task_address } = this.state
    const param_id = this.props.match.params.id
    getAirdropDetailInfo(param_id)
    getDropDetailAddressList(
      param_id,
      task_skip,
      task_limit,
      task_state,
      task_address
    )
    this.setState({
      param_id: param_id,
    })
  }

  renderInfo = () => {
    const { dropDetailInfo } = this.props.dropStore
    return (
      <Fragment>
        <Row>
          <Col span={8}>
            <span>空投名称：</span>
            <span>{dropDetailInfo.airdropName}</span>
          </Col>
          <Col span={8}>
            <span>空投金额：</span>
            <span>
              {dropDetailInfo.airdropTotalToken || '-'}
              {` ${dropDetailInfo.assetSymbol}`}
            </span>
          </Col>
          <Col span={8}>
            <span>手续费：</span>
            <span>
              {dropDetailInfo.airdropFee}
              {` ${OriginToken}`}
            </span>
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <span>编号：</span>
            <span>{dropDetailInfo.airdropId}</span>
          </Col>
          <Col span={8}>
            <span>创建时间：</span>
            <span>{dropDetailInfo.createdAtFormat}</span>
          </Col>
        </Row>
        <Row>
          <Col span={20}>
            <span>空投状态：</span>
            <span>
              {dropDetailInfo.state === 1 && '未准备'}
              {dropDetailInfo.state === 2 && '准备完成'}
              {dropDetailInfo.state === 3 && '进行中'}
              {dropDetailInfo.state === 4 &&
                `已完成 计划空投${dropDetailInfo.addressAmount}个地址，实际成功${dropDetailInfo.airdropTaskSuccessfulAmount}个，失败
              ${dropDetailInfo.airdropTaskFailedAmount}个`}
              {dropDetailInfo.state === 5 && '空投失败'}
            </span>
          </Col>
          <Col span={4}>
            {dropDetailInfo.airdropTaskFailedAmount !== 0 && (
              <Button onClick={this.showConfirm}>继续空投</Button>
            )}
          </Col>
        </Row>
      </Fragment>
    )
  }

  showConfirm = airdropId => {
    confirm({
      title: '确定继续空投吗？',
      onOk: () => {
        this.doAirdropAgain()
      },
      onCancel: () => {},
    })
  }

  doAirdropAgain = () => {
    const { param_id } = this.state
    this.props.dropStore.restartAirdrop(param_id)
  }

  doSelectChange = state => {
    const { param_id, task_skip, task_limit, task_address } = this.state
    if (state === 0) {
      state = ''
    }
    this.props.dropStore.getDropDetailAddressList(
      param_id,
      task_skip,
      task_limit,
      state,
      task_address
    )
    this.setState({
      task_state: state,
    })
  }

  doInputChange = e => {
    this.setState({
      task_address: e.target.value,
    })
  }

  doSearchTask = () => {
    const {
      param_id,
      task_skip,
      task_limit,
      task_state,
      task_address,
    } = this.state
    this.props.dropStore.getDropDetailAddressList(
      param_id,
      task_skip,
      task_limit,
      task_state,
      task_address
    )
  }

  renderBar = () => {
    return (
      <Row>
        <Col span={10} />
        <Col span={4}>
          <Select
            defaultValue={0}
            suffixIcon={<Icon type="more" />}
            onChange={this.doSelectChange}
          >
            <Option value={0}>全部</Option>
            <Option value={1}>进行中</Option>
            <Option value={2}>成功</Option>
            <Option value={3}>失败</Option>
          </Select>
        </Col>
        <Col span={8}>
          <Input
            placeholder="请输入您要查询的地址"
            onChange={this.doInputChange}
          />
        </Col>
        <Col span={2}>
          <Button onClick={this.doSearchTask}>搜索</Button>
        </Col>
      </Row>
    )
  }

  doPageChange = (current, pageSize) => {
    const { param_id, task_limit, task_state, task_address } = this.state
    this.props.dropStore.getDropDetailAddressList(
      param_id,
      (current - 1) * pageSize,
      task_limit,
      task_state,
      task_address
    )
  }

  rendertable = () => {
    const { dropDetailList, dropDetailListTotal } = this.props.dropStore
    return (
      <Table
        rowKey={item => item.id}
        columns={this.state.col_addr}
        dataSource={dropDetailList}
        pagination={{
          total: dropDetailListTotal,
          defaultPageSize: 10,
          onChange: this.doPageChange,
          hideOnSinglePage: true,
        }}
      />
    )
  }

  render() {
    return (
      <div className="dropdetail">
        <Card className="contain">
          <h1>空投详情</h1>
          <div className="info">{this.renderInfo()}</div>
          <div className="bar">{this.renderBar()}</div>
          <div className="table">{this.rendertable()}</div>
        </Card>
      </div>
    )
  }
}

export default DropDetail
