import React, { Component, Fragment } from 'react'
import { observer, inject } from 'mobx-react'
import { Card, Row, Col, Divider, Input, Button, Modal } from 'antd'
import QRCode from 'qrcode.react'

import './index.less'

import icon_l from '../../../images/pop-0.png'
import icon_r from '../../../images/pop-1.png'
import { QR } from '../../../config'

@inject('assetsStore')
@observer
class DetailMint extends Component {
  constructor(props) {
    super(props)
    this.state = {
      flag_step: false,
      param_address: '',
      param_total: '',
      flag_address: false,
      flag_total: false,
      assetId: '',
      maxAmount: 10000000000000
    }
  }

  componentDidMount() {
    const assetId = this.props.match.params.id
    const currentCardAddress = localStorage.getItem('currentCardAddress')
    this.props.assetsStore.getMintDetail(currentCardAddress, assetId)
    this.setState({
      assetId: assetId
    })
  }

  renderTipBar = () => {
    return (
      <div className='tip-bar'>
        <Row>
          <Col span={21} offset={3}>
            温馨提示：请确认增发资产参数
          </Col>
        </Row>
      </div>
    )
  }

  renderComfirm = () => {
    const { mintDetailInfo } = this.props.assetsStore
    const TYPE_FIXED = 1
    return (
      <div className='form'>
        <Row>
          <Col span={3} offset={3}>
            <div className='label'>资产类型</div>
          </Col>
          <Col span={15}>
            <span className='value'>{mintDetailInfo.type === TYPE_FIXED && '固定资产'}</span>
          </Col>
        </Row>
        <Row>
          <Col span={3} offset={3}>
            <div className='label'>资产名称</div>
          </Col>
          <Col span={15}>
            <span className='value'>{mintDetailInfo.assetName}</span>
          </Col>
        </Row>
        <Row>
          <Col span={3} offset={3}>
            <div className='label'>资产符号</div>
          </Col>
          <Col span={15}>
            <span className='value'>{mintDetailInfo.assetSymbol}</span>
          </Col>
        </Row>
        <Row>
          <Col span={3} offset={3}>
            <div className='label'>小数位</div>
          </Col>
          <Col span={15}>
            <span className='value'>{mintDetailInfo.decimals}</span>
          </Col>
        </Row>
        <Row>
          <Col span={3} offset={3}>
            <div className='label'>发行总量</div>
          </Col>
          <Col span={15}>
            <span className='value'>
              {mintDetailInfo.totalSupply} {mintDetailInfo.assetSymbol}
            </span>
          </Col>
        </Row>
        <Row>
          <Col span={3} offset={3}>
            <div className='label'>管理员地址</div>
          </Col>
          <Col span={15}>
            <span className='value'>{mintDetailInfo.managerAddress}</span>
          </Col>
        </Row>
      </div>
    )
  }

  setParamAddress = e => {
    let temp = false
    let value = e.target.value
    let regAddress = /^[0-9a-zA-Z]+$/
    if (!regAddress.test(value) || value.indexOf('0x') !== 0 || value.length !== 42) {
      temp = true
    }
    this.setState({
      param_address: value,
      flag_address: temp
    })
  }

  setParamTotal = e => {
    const { mintDetailInfo } = this.props.assetsStore
    const { maxAmount } = this.state
    let temp = false
    let value = e.target.value
    let regTotal = /^(0|[1-9][0-9]*)$/
    let mintBalance = maxAmount - Number(mintDetailInfo.totalSupply)
    if (!regTotal.test(value) || Number(value) < 1 || Number(value) > mintBalance) {
      temp = true
    }
    this.setState({
      param_total: value,
      flag_total: temp
    })
  }

  check = () => {
    if (!this.state.param_address) {
      this.setState({
        flag_address: true
      })
      return
    }
    if (!this.state.param_total) {
      this.setState({
        flag_total: true
      })
      return
    }
    if (this.state.flag_address || this.state.flag_total) {
      return
    }
    this.doConfirm()
  }

  renderForm = () => {
    const { mintDetailInfo } = this.props.assetsStore
    const { maxAmount } = this.state
    let mintBalance = maxAmount - mintDetailInfo.totalSupply
    mintBalance = mintBalance === 0 ? 0 : `1～${mintBalance}`
    return (
      <Fragment>
        <Divider />
        <div className='form'>
          <Row>
            <Col span={3} offset={3}>
              <div className='label'>增发地址</div>
            </Col>
            <Col span={15}>
              <div className='input-box'>
                <Input
                  placeholder='请输入您的增发地址'
                  onChange={e => this.setParamAddress(e)}
                  value={this.state.param_address}
                />
              </div>
              <div className='tip'>{this.state.flag_address && '地址输入错误'}</div>
            </Col>
          </Row>
          <Row>
            <Col span={3} offset={3}>
              <div className='label'>增发总量</div>
            </Col>
            <Col span={15}>
              <div className='input-box'>
                <Input
                  placeholder={`请输入您的增发总量，当前增发余量为${mintBalance}`}
                  onChange={e => this.setParamTotal(e)}
                  value={this.state.param_total}
                />
                <span>{mintDetailInfo.assetSymbol}</span>
              </div>
              <div className='tip'>
                {this.state.flag_total && `您输入的总量有误，当前增发余量为${mintBalance}`}
              </div>
            </Col>
          </Row>
          <div className='btn-box'>
            <Button size='large' disabled={false} onClick={this.check}>
              下一步
            </Button>
          </div>
        </div>
      </Fragment>
    )
  }

  renderFormComfirm = () => {
    return (
      <div className='form'>
        <Row>
          <Col span={3} offset={3}>
            <div className='label'>增发地址</div>
          </Col>
          <Col span={15}>
            <span className='value'>{this.state.param_address}</span>
          </Col>
        </Row>
        <Row>
          <Col span={3} offset={3}>
            <div className='label'>增发总量</div>
          </Col>
          <Col span={15}>
            <span className='value'>{this.state.param_total}</span>
          </Col>
        </Row>
        <Row>
          <Col span={3} offset={3}>
            <Button size='large' onClick={this.doBack}>
              返回
            </Button>
          </Col>
          <Col span={15}>
            <Button className='ant-btn-long' size='large' onClick={this.doShowModal}>
              确认
            </Button>
          </Col>
        </Row>
      </div>
    )
  }

  doShowModal = () => {
    this.props.assetsStore.doStartMint(this.state.assetId)
    this.props.assetsStore.showMintModal()
  }

  doConfirm = () => {
    this.setState({
      flag_step: true
    })
  }

  doBack = () => {
    this.setState({
      flag_step: false
    })
  }

  renderModal = () => {
    const {
      mintModalStatus,
      hideMintModal,
      mintScanStatus,
      mintTxId,
      mintDetailInfo
    } = this.props.assetsStore
    const { param_address, param_total } = this.state
    let qrInfo = QR.mint(mintTxId, mintDetailInfo.id, param_address, param_total)
    return (
      <Modal
        title='增发签名授权'
        visible={mintModalStatus}
        onOk={hideMintModal}
        onCancel={hideMintModal}
        footer={null}
        closable={false}
      >
        <Row>
          <Col className='top' span={10} offset={7}>
            <img src={icon_l} alt='' />
            <div className='dashed' />
            <img src={icon_r} alt='' />
          </Col>
        </Row>
        <Row>
          <Col className='cen'>请打开鲸矿APP扫码</Col>
        </Row>
        <Row>
          <Col className='btm' span={10} offset={7}>
            <div className='box'>
              {mintScanStatus === 1 ? (
                <div className='mask'>
                  <span className='pending'>等待中...</span>
                </div>
              ) : null}
              <div className='qrcode-box'>
                <QRCode value={qrInfo} size={180} renderAs={'svg'} />
              </div>
            </div>
          </Col>
        </Row>
      </Modal>
    )
  }

  render() {
    return (
      <div className='mint'>
        <Card className='contain'>
          <h1>资产详情/增发资产</h1>
          {this.renderTipBar()}
          {this.renderComfirm()}
          {!this.state.flag_step && this.renderForm()}
          {this.state.flag_step && this.renderFormComfirm()}
        </Card>
        {this.renderModal()}
      </div>
    )
  }
}

export default DetailMint
