import { private_policy } from './policy'
import { areaCode } from './areaCode'

const copyright =
  'Copyright © 2016-2019 ShangHai Yuhu , Ltd. All rights reserved. 沪ICP备16033604号-1'

const QR = {
  mint: (txId, assedId, mintAddress, mintTotal) => {
    return `${server}/transaction/?type=1&txid=${txId}&assetId=${assedId}&mintAddress=${mintAddress}&mintTotal=${mintTotal}`
  },
  burn: (txId, assetId, burnAddress, butnTotal) => {
    return `${server}/transaction/?type=2&txid=${txId}&assetId=${assetId}&burnAddress=${burnAddress}&burnTotal=${butnTotal}`
  },
  move: (txId, assetId, targetAddress) => {
    return `${server}/transaction/?type=3&txid=${txId}&assetId=${assetId}&targetAddress=${targetAddress}`
  },
  forbidden: (type, txId, assetId) => {
    return `${server}/transaction/?type=${type}&txid=${txId}&assetId=${assetId}`
  },
  airdrop: (txid, airdropId) => {
    return `${server}/transaction/?type=6&txid=${txid}&airdropId=${airdropId}`
  },
}

const OriginToken = 'GA'

let server = ''
let ws_server = ''

switch (process.env.NODE_ENV) {
  case 'development':
    server = `${window.location.protocol}//assets.yuhu.tech`
    ws_server = `${
      window.location.protocol === 'https:' ? 'wss:' : 'ws:'
    }//assets.yuhu.tech`
    break
  case 'production':
    server = `${window.location.protocol}//assets.yuhu.tech`
    ws_server = `${
      window.location.protocol === 'https:' ? 'wss:' : 'ws:'
    }//assets.yuhu.tech`
    break
  case 'test':
    server = `${window.location.protocol}//192.168.1.29:3000`
    ws_server = `${
      window.location.protocol === 'https:' ? 'wss:' : 'ws:'
    }//192.168.1.29:3000`
    break

  default:
    server = `${window.location.protocol}//192.168.1.29:3000`
    ws_server = `${
      window.location.protocol === 'https:' ? 'wss:' : 'ws:'
    }//192.168.1.29:3000`
    break
}

const config = {
  host: `${server}/query`,
  host_upload: `${server}/upload`,
  explorer: 'http://browser.yuhu.tech',
  explorer_txs: 'http://browser.yuhu.tech/#/txs',
  ws_host: `${ws_server}/query`,
  open_transaction: `${server}/transaction`,
  host_route: window.location.origin,
  csvTemplateUrl: `${server}/storage/csv/airdrop_task_templete.csv`,
}

export { private_policy, copyright, config, QR, OriginToken, areaCode }
