import { observable, action } from 'mobx'
import { message } from 'antd'
import { query, mutation, upload, subscription } from '../../common/apollo'
import moment from 'moment'

import { config } from '../../config'

export default class DropStore {
  /* obserable */
  @observable
  dropListTotal = 0

  @observable
  dropList = []

  @observable
  dropDetailInfo = ''

  @observable
  dropDetailListTotal = 0

  @observable
  dropDetailList = []

  @observable
  csvInfo = {}

  @observable
  csvList = []

  @observable
  csvInfoFlag = 0

  @observable
  editAirdropState = 0

  @observable
  dropAssetsList = []
  /* sub */
  @observable
  airdropModalStatus = false

  @observable
  airdropScanStatus = 0

  @observable
  airdropTxId = ''

  /* action */
  @action
  getDropList = async (accountAddress, skip, limit) => {
    const variables = {
      accountAddress: accountAddress,
      skip: skip,
      limit: limit
    }
    const body = `
      query getDropList(
        $accountAddress: String!
        $skip: Int!
        $limit: Int!
      ){
        airdrops(
          accountAddress:$accountAddress
          skip:$skip
          limit:$limit
        ){
          totalCount
          airdrops{
            airdropId
            airdropName
            createdAt
            airdropTotalToken
            state
          }
        }
      }
    `

    let res = await query(body, variables)
    if (res) {
      for (let item of res.airdrops.airdrops) {
        let createTimestamp = item.createdAt
        item.createdAtFormat = moment.unix(createTimestamp).format('YYYY-MM-DD HH:mm:ss') || '-'
      }
      this.dropListTotal = res.airdrops.totalCount
      this.dropList = res.airdrops.airdrops
    }
  }

  @action
  deleteAirdrop = async airdropId => {
    const variables = {
      airdropId: airdropId
    }
    const body = `
      mutation deleteAirdrop(
        $airdropId: String!
      ){
        deleteAirdrop(
          airdropId:$airdropId
        )
      }
    `

    return await mutation(body, variables)
  }

  @action
  getDropDetailAddressList = async (airdropId, skip, limit, state, toAddress) => {
    const variables = {
      airdropId: airdropId,
      skip: skip,
      limit: limit,
      state: state,
      toAddress: toAddress
    }
    const body = `
      query getAddressList(
        $airdropId: String!
        $skip: Int!
        $limit: Int!
        ${state && `$state: Int`}
        ${toAddress && `$toAddress: String`}
      ){
        airdropTask(
          airdropId:$airdropId
          skip:$skip
          limit:$limit
          ${state && `state: $state`}
          ${toAddress && `toAddress: $toAddress`}
        ){
          totalCount
          airdropTaskses{
            id
            targetAddress
            value
            state
            txhash
          }
        }
      }
    `
    let res = await query(body, variables)
    if (res) {
      this.dropDetailListTotal = res.airdropTask.totalCount
      this.dropDetailList = res.airdropTask.airdropTaskses
    }
  }

  @action
  newAirDrop = async accountAddress => {
    const variables = {
      accountAddress: accountAddress
    }
    const body = `
      mutation newAirDrop(
        $accountAddress: String!
      ){
        newAirdrop(
          accountAddress:$accountAddress
        ){
          airdropId
        }
      }
    `
    let res = await mutation(body, variables)
    return res.newAirdrop.airdropId
  }

  @action
  getFuzzyAssets = async str => {
    const variables = {
      str: str
    }
    const body = `
      query getFuzzyAssets(
        $str: String!
      ){
        assetses(
          str:$str
        ){
          assetSymbol
          contractAddress
        }
      }
    `
    let res = await query(body, variables)
    if (res) {
      this.dropAssetsList = res.assetses
    }
  }

  @action
  uploadCsv = async (airdropId, file) => {
    message.loading('上传CSV中...', 0)
    const variables = {
      file: file
    }
    const body = `
      mutation uploadCsv(
        $file: Upload!
      ){
        singleUpload(
          file:$file
        ){
          fileName
          size
        }
      }
    `
    let res = await upload(body, variables)
    if (res) {
      let fileName = res.singleUpload.fileName
      this.getCsvList(airdropId, fileName)
    }
    message.destroy()
  }

  @action
  getCsvList = async (airdropId, fileName) => {
    message.loading('导入CSV中...', 0)
    const variables_import = {
      id: airdropId,
      fileName: fileName
    }
    const body_import = `
      mutation importCsv(
        $id: String!
        $fileName: String!
      ){
        importAirdropList(
          id:$id
          fileName:$fileName
        ){
          addressAmount
          errorAmount
          tokenAmount
          result
          airdropFee
          errorContents {
            num
            type
          }
        }
      }
    `
    let res_import = await mutation(body_import, variables_import)
    if (res_import) {
      let temp_info = res_import.importAirdropList
      this.csvInfo = temp_info
      for (let [index, item] of temp_info.errorContents.entries()) {
        item.id = index + 1
        switch (item.type) {
          case 1:
            item.message = '地址格式错误'
            break
          case 2:
            item.message = '地址重复'
            break
          case 3:
            item.message = '金额无效'
            break
          default:
            break
        }
      }
      this.csvErrorList = temp_info.errorContents
      if (temp_info.errorAmount === 0) {
        this.csvInfoFlag = 1
      } else {
        this.csvInfoFlag = 2
      }
    }
    message.destroy()
  }

  @action
  saveAirdrop = async (airdropId, airdropName, assetSymbol, assetAddress) => {
    const variables = {
      airdropId: airdropId,
      airdropName: airdropName,
      assetSymbol: assetSymbol,
      assetAddress: assetAddress
    }
    const body = `
      mutation saveAirdrop(
        $airdropId: String!
        $airdropName: String!
        $assetSymbol: String!
        $assetAddress: String!
      ){
        editAirdrop(
          airdropId: $airdropId
          airdropName: $airdropName
          assetSymbol: $assetSymbol
          assetAddress: $assetAddress
        ){
          airdropId
        }
      }
    `
    let res = await mutation(body, variables)
    if (res) {
      return true
    } else {
      return false
    }
  }

  @action
  getAirdropDetailInfo = async airdropId => {
    const variables = {
      airdropId: airdropId
    }
    const body = `
      query getAirdropDetailInfo(
        $airdropId: String!
      ){
        airdrop(
          airdropId:$airdropId
        ){
          airdropName
          airdropTotalToken
          airdropFee
          createdAt
          state
          addressAmount
          assetAddress
          assetSymbol
          airdropId
          airdropTaskSuccessfulAmount
          airdropTaskFailedAmount
        }
      }
    `
    let res = await query(body, variables)
    if (res) {
      let createTimestamp = res.airdrop.createdAt
      res.airdrop.createdAtFormat =
        moment.unix(createTimestamp).format('YYYY-MM-DD HH:mm:ss') || '-'
      this.dropDetailInfo = res.airdrop
    }
  }

  @action
  getAirdropInfo = async airdropId => {
    const variables = {
      airdropId: airdropId
    }
    const body = `
      query getAirdropInfo(
        $airdropId: String!
      ){
        airdrop(
          airdropId:$airdropId
        ){
          airdropName
          assetSymbol
          assetAddress
          airdropFee
          csvFilePath
          state
        }
      }
    `
    let res = await query(body, variables)
    if (res) {
      this.editAirdropState = res.airdrop.state
      if (res.airdrop.csvFilePath) {
        await this.getCsvList(airdropId, res.airdrop.csvFilePath)
      }
      return res.airdrop
    }
  }

  @action
  resetCsvInfoFlag = () => {
    this.csvInfoFlag = 0
  }

  /* subscription */
  @action
  doStartAirdrop = async () => {
    let that = this
    const variables = null
    const body = `
      mutation getTxid{
        startTrading
      }`
    let res = await mutation(body, variables)
    if (res) {
      this.airdropTxId = res.startTrading
      let wsVariables = {
        txId: res.startTrading
      }
      let wsBody = `
        subscription checkTrading(
          $txId: String!
        ) {
          checkTrading(
            txId: $txId
          )
        }
      `
      subscription(wsBody, wsVariables).then(res => {
        res.subscribe({
          next(data) {
            let checkTrading = data.data.checkTrading
            if (checkTrading === 2) {
              that.hideAirdropModal()
              message.success('空投已发起')
              setTimeout(
                window.location.replace(`${config.host_route}/#/assets/airdrop/list`),
                1000
              )
            }
            that.airdropScanStatus = checkTrading
          }
        })
      })
    }
  }

  @action
  restartAirdrop = async airdropId => {
    const variables = {
      airdropId: airdropId
    }
    const body = `
      mutation restartAirdrop(
        $airdropId: String!
      ){
        addReAirdropRecord(
          airdropId:$airdropId
        )
      }
    `
    let res = await mutation(body, variables)
    if (res) {
      if (res.addReAirdropRecord) {
        message.success('已开始继续空投')
      } else {
        message.error('继续空投失败')
      }
    }
  }

  @action
  showAirdropModal = () => {
    this.airdropModalStatus = true
  }

  @action
  hideAirdropModal = () => {
    this.airdropScanStatus = 0
    this.airdropModalStatus = false
  }
}
