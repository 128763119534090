import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import { Row, Col, Card, Button, Input, Modal, message } from 'antd'
import QRCode from 'qrcode.react'
import './index.less'
import { config } from '../../../config'

import icon_l from '../../../images/pop-0.png'
import icon_r from '../../../images/pop-1.png'

@inject('txsStore', 'userStore', 'verifyStore')
@observer
class Trans extends Component {
  constructor(props) {
    super(props)
    this.state = {
      address: '',
      qrcodeMsg: config.open_transaction
    }
  }

  async componentDidMount() {
    const { verifyStore } = this.props
    const { getVerificationStatus } = verifyStore
    let authenticationStatus = await getVerificationStatus()
    if (authenticationStatus !== 1) {
      message.info('请先完成实名认证')
      this.props.history.goBack()
    }
  }

  handleChange = () => {}

  setAddress = e => {
    this.setState({
      address: e.target.value
    })
  }

  check = async () => {
    if (!this.state.address) {
      message.error('请输入付款地址')
      return
    }
    if (this.state.address.indexOf('0x') !== 0) {
      message.error('地址必须以0x开头')
      return
    }
    if (this.state.address.length !== 42) {
      message.error('地址位数错误')
      return
    }
    this.props.txsStore.getTxid()
    this.props.txsStore.showModal()
  }

  handleOk = () => {
    this.props.txsStore.hideModal()
  }

  handleCancel = () => {
    this.props.txsStore.hideModal()
  }

  renderModal = () => {
    const { txsStore } = this.props
    const { isModalShow, txid, scanStatus } = txsStore
    let qrstr = `${this.state.qrcodeMsg}?txid=${txid}&to=${this.state.address}`
    return (
      <Modal
        title='转账签名授权'
        visible={isModalShow}
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        footer={null}
        closable={false}
      >
        <Row>
          <Col className='top' span={10} offset={7}>
            <img src={icon_l} alt='' />
            <div className='dashed' />
            <img src={icon_r} alt='' />
          </Col>
        </Row>
        <Row>
          <Col className='cen'>请打开鲸矿APP扫码完成安全转账</Col>
        </Row>
        <Row>
          <Col className='btm' span={10} offset={7}>
            <div className='box'>
              {scanStatus === 1 ? (
                <div className='mask'>
                  <span className='pending'>等待转账中...</span>
                </div>
              ) : null}
              <div className='qrcode-box'>
                <QRCode value={qrstr} size={180} renderAs={'svg'} />
              </div>
            </div>
          </Col>
        </Row>
      </Modal>
    )
  }

  render() {
    return (
      <div className='trans'>
        <Card>
          <h1>转账</h1>
          <div className='form'>
            <Row>
              <Col span={3} offset={3}>
                <div className='label'>收款地址</div>
              </Col>
              <Col span={15}>
                <div className='input-box'>
                  {/* <div className='addr-name'>名称</div> */}
                  <Input placeholder='请输入您的收款地址' onChange={this.setAddress} />
                </div>
              </Col>
            </Row>
            <div className='btn-box'>
              <Button size='large' onClick={this.check}>
                下一步
              </Button>
            </div>
          </div>
        </Card>
        {this.renderModal()}
      </div>
    )
  }
}

export default Trans
