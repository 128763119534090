import { observable, action } from 'mobx'
import { query, mutation } from '../../common/apollo'
import { message } from 'antd'

export default class UserStore {
  /* obserable */
  @observable
  isLogin = 0

  @observable
  param_code_status = false

  @observable
  loading = false

  @observable
  userInfo = {
    phoneNumber: '',
    awardsNumber: '',
  }

  @observable
  cardList = []

  @observable
  currentCardIndex = 0

  @observable
  currentCard = {}

  /* action */
  @action
  setLoginStatus = () => {
    if (!localStorage.getItem('isLogin')) {
      localStorage.setItem('isLogin', 0)
      this.isLogin = 0
    } else {
      this.isLogin = 1
    }
  }

  @action
  sendVerificationCode = async param_phone => {
    const body = `
      mutation getCode(
        $phoneNumber: String!
      ) {
        sendVerificationCode(
          phoneNumber: $phoneNumber
        )
      }
    `
    const variables = {
      phoneNumber: param_phone,
    }
    let res = await mutation(body, variables)
    if (res) {
      this.param_code_status = res.sendVerificationCode
    }
  }

  @action
  login = async (param_phone, param_code) => {
    this.loading = true
    const body = `
      mutation login(
        $phoneNumber: String!
        $verificationCode:String!
      ){
        signupOrLogin(
          phoneNumber:$phoneNumber
          verificationCode:$verificationCode
        ){
          token
          refreshToken
          expiredDate
        }
      }`
    const variables = {
      phoneNumber: param_phone,
      verificationCode: param_code,
    }
    let res = await mutation(body, variables)
    this.loading = false
    if (res) {
      localStorage.clear()
      localStorage.setItem('isLogin', 1)
      this.isLogin = 1
      localStorage.setItem('token', res.signupOrLogin.token)
      localStorage.setItem('refreshToken', res.signupOrLogin.refreshToken)
      localStorage.setItem('expiredDate', res.signupOrLogin.expiredDate)
      return true
    } else {
      return false
    }
  }

  @action
  logout = () => {
    localStorage.clear()
    this.setLoginStatus()
  }

  @action
  getUserInfo = async () => {
    const body = `
      query{
        user{
          id
          phoneNumber
          certificationRewards {
            id
          }
        }
      }`
    const variables = null
    let res = await query(body, variables)
    if (res) {
      let data = res.user
      this.userInfo['phoneNumber'] = data.phoneNumber
      this.userInfo['awardsNumber'] = data.certificationRewards.length
    }
  }

  @action
  getCardList = async () => {
    const body = `
      query{
        user{
          addresses{
            id
            addressString
            nickname
          }
        }
      }`
    const variables = null
    let res = await query(body, variables)

    if (res) {
      let addressList = res.user.addresses
      this.cardList = addressList
      /* non-card, return false to push /process */
      if (addressList.length === 0) {
        return false
      }
      /* non-localStorege, set arr[0] as default */
      const isSetLocalStorage = localStorage.getItem('currentCardAddress')
      const isContain = await this.checkIsContain(
        addressList,
        isSetLocalStorage
      )

      if (!isSetLocalStorage || !isContain) {
        await this.setCurrentCard(addressList)
      }
      /* nth err, retrn true to push /home */
      return true
    }
  }

  @action
  checkIsContain = (addressList, isSetLocalStorage) => {
    let temp = false
    if (isSetLocalStorage) {
      for (let item of addressList) {
        if (item.addressString === isSetLocalStorage) {
          temp = true
          break
        }
      }
    }
    return temp
  }

  @action
  setCurrentCard = addressList => {
    localStorage.setItem('currentCardIndex', 0)
    localStorage.setItem('currentCardId', addressList[0].id)
    localStorage.setItem('currentCardName', addressList[0].nickname)
    localStorage.setItem('currentCardAddress', addressList[0].addressString)
  }

  @action
  updateCurrentCard = index => {
    const tempCurrentCard = this.cardList[index]
    this.currentCardIndex = index
    this.currentCard = tempCurrentCard
    localStorage.setItem('currentCardIndex', index)
    localStorage.setItem('currentCardId', tempCurrentCard.id)
    localStorage.setItem('currentCardName', tempCurrentCard.nickname)
    localStorage.setItem('currentCardAddress', tempCurrentCard.addressString)
  }

  @action
  receiveAwards = async toAddress => {
    const body = `
      mutation onReceiveCertificationReward(
        $toAddress: String!
      ){
        receiveCertificationReward(
          toAddress:$toAddress
        )
      }`
    const variables = {
      toAddress: toAddress,
    }
    let res = await mutation(body, variables)
    if (res) {
      if (res.receiveCertificationReward) {
        this.userInfo.awardsNumber--
        message.success('领取成功')
      } else {
        message.error('领取失败')
      }
    }
  }
}
