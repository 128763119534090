let errMsg = message => {
  let text = ''
  switch (message) {
    // 通用模块
    case 'credentials error':
      text = '登录错误'
      break
    case 'query returned no result':
      text = '未从数据库查询到数据'
      break
    case 'address is invalid':
      text = '地址格式错误'
      break
    case 'hash is invalid':
      text = '哈希值格式错误'
      break
    case 'paging parameters error':
      text = '分页参数错误'
      break
    case 'int parameter is under zero':
      text = '输入的整数值必须大于0'
      break
    case 'address do not belong to you':
      text = '请勿查询不属于此用户的鲸卡信息'
      break
    case 'contacter do not belong to you':
      text = '请勿查询不属于此用户的通讯录信息'
      break
    case 'assets do not belong to you':
      text = '请勿查询不属于此用户的资产信息'
      break
    case 'http response error':
      text = 'HTTP响应异常'
      break
    case 'query balance of error':
      text = '查询账户token错误'
      break
    case 'record operation log error':
      text = '记录操作日志失败'
      break
    // 短信验证模块
    case 'verification code error':
      text = '验证码错误'
      break
    case 'create user error':
      text = '创建用户失败'
      break
    case 'send message by ali error':
      text = '发送验证短信失败'
      break
    case 'verification is locked':
      text = '验证错误次数过多，账户已锁定'
      break
    case 'send verification code too frequency':
      text = '发送验证短信频率过高'
      break
    // 认证模块
    case 'resolve image error':
      text = '图片解析错误'
      break
    case 'recognition error':
      text = '图片文字信息识别不全'
      break
    case 'identify card inforamtion error':
      text = '身份证信息识别不全'
      break
    case 'bussiness license information error':
      text = '营业执照信息识别不全'
      break
    case 'can not repeat certification':
      text = '请勿重复认证'
      break
    case 'certificate error':
      text = '填写的信息与图片信息不一致'
      break
    case 'certification overdue':
      text = '认证信息过期'
      break
    case 'entry duplicated IDCardNo':
      text = '该身份证已被认证'
      break
    // 奖励模块
    case 'reward content error':
      text = '查询奖励内容错误'
      break
    case 'no reward can be reveived':
      text = '没有奖励可以被领取'
      break
    // 资产模块
    case 'publish assets error':
      text = '发布资产错误'
      break
    case 'the assets can not be deleted':
      text = '发布成功的资产不能被删除'
      break
    case 'query operation log failed':
      text = '查询日志信息失败'
      break
    case 'add mint record failed':
      text = '资产增发错误'
      break
    case 'add burn record failed':
      text = '资产销毁错误'
      break
    case 'add transferOwner record failed':
      text = '资产所有权转移错误'
      break
    case 'add pause record failed':
      text = '资产禁止流通错误'
      break
    case 'add unPause record failed':
      text = '资产解禁错误'
      break
    case 'query holding conditions failed':
      text = '查询资产持有情况失败'
      break
    // 空投模块
    case 'new a airdrop record failed':
      text = '创建新的空投失败'
      break
    case 'import airdrop list failed':
      text = '导入空投列表失败'
      break
    case 'edit airdrop failed':
      text = '编辑空投错误'
      break
    case 'query airdrop by id failed':
      text = '查询空投详情失败'
      break
    case 'query airdrop tasks failed':
      text = '查询空投任务失败'
      break
    case 'airdrop error':
      text = '空投失败'
      break
    case 'can not reairdrop':
      text = '空投未完成或者没有失败空投任务'
      break
    case 'reairdrop failed':
      text = '重新空头失败'
      break
    // 其他模块
    case 'article title can not be empty':
      text = '资讯标题内容不能为空'
      break

    default:
      text = message
      break
  }
  return text
}

export { errMsg }
