import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import { Button, message } from 'antd'
// eslint-disable-next-line
import './index.less'

import publish_process from '../../images/publish_process.png'

@inject('userStore')
@observer
class Process extends Component {
  componentDidMount = async () => {
    const { history, userStore } = this.props
    const { getCardList } = userStore
    let hasCard = await getCardList()
    if (hasCard) {
      message.info('您已创建鲸卡，为您跳转至首页')
      history.push('/assets/home')
    }
  }

  getCard = async () => {
    const { userStore } = this.props
    const { getCardList } = userStore

    await getCardList()
    this.checkHasCard()
  }

  checkHasCard = () => {
    const { history } = this.props
    const currentCardAddress = localStorage.getItem('currentCardAddress')

    if (currentCardAddress) {
      history.push('/assets/home')
    } else {
      message.warn('请先创建鲸卡')
    }
  }

  render() {
    return (
      <div className='process-wrp'>
        <h3 className='process-title'>请先下载鲸矿APP, 创建鲸卡后即可发布资产</h3>
        <div className='process-step'>
          <p>第一步：下载APP --------------------------</p>
          <p>第二步：创建鲸卡 --------------------------</p>
          <p>第三步：返回网站发布资产</p>
        </div>
        <div className='process-step-bg'>
          <img src={publish_process} alt='publish_process' />
        </div>
        <div className='process-submit-btn'>
          <Button size='large' onClick={this.getCard}>我已创建完成</Button>
        </div>
      </div>
    )
  }
}

export default Process
