import { message } from 'antd'
// 获取当前日期 格式YYYY-MM-DD
export function getFormatDate(timestamp = '') {
  var date = new Date(timestamp * 1000)
  var seperator1 = '-'
  var year = date.getFullYear()
  var month = date.getMonth() + 1
  var strDate = date.getDate()
  if (month >= 1 && month <= 9) {
    month = '0' + month
  }
  if (strDate >= 0 && strDate <= 9) {
    strDate = '0' + strDate
  }
  var hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
  var minute = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
  /* var second = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds() */
  var currentdate = year + seperator1 + month + seperator1 + strDate + ' ' + hour + ':' + minute
  return currentdate
}

// 日期比较
export function compareDate(starttime, endtime) {
  // eslint-disable-next-line
  return new Date(starttime.replace(/-/g, '/')) > new Date(endtime)
}

// 验证是否为邮箱
export function checkEmail(email) {
  var re = /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/
  if (re.test(email)) {
    return true
  } else {
    return false
  }
}

// 验证是否为身份证
export function checkIdNum(idnum) {
  var re = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/
  if (re.test(idnum)) {
    return true
  } else {
    return false
  }
}

// 验证是否为电话号码
export function checkPhoneNum(phone) {
  var re = /^1[34578]\d{9}$/
  if (re.test(phone)) {
    return true
  } else {
    return false
  }
}

// 上传图片获取base_64
export function getImgBase64(file) {
  return new Promise((resolve, reject) => {
    if (!file) {
      return
    }
    if (file.size / 1024 / 1024 > 3) {
      message.error('上传照片不能大于3M')
      return
    }
    // 确认选择的文件是图片
    if (file.type.indexOf('image') === 0) {
      var reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = function(e) {
        // 图片base64化
        var newUrl = this.result
        resolve(newUrl)
      }
    }
  })
}
