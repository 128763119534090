import React, { Component, Fragment } from 'react'
import { observer, inject } from 'mobx-react'
import { Card, Button, Table, message, Modal } from 'antd'
import { Link } from 'react-router-dom'

import './index.less'

const { confirm } = Modal

@inject('userStore', 'dropStore')
@observer
class DropList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      defaultSkip: 0,
      defaultLimit: 10,
      col_drop: [
        {
          title: '空投名称',
          dataIndex: 'airdropName',
          width: '15%',
          render: text => <span>{text || '-'}</span>,
        },
        {
          title: '时间',
          dataIndex: 'createdAtFormat',
          width: '20%',
        },
        {
          title: '空投金额',
          dataIndex: 'airdropTotalToken',
          width: '25%',
          render: text => <span>{text || '-'}</span>,
        },
        {
          title: '状态',
          dataIndex: 'state',
          width: '20%',
          render: text => (
            <span className={(text !== 4 && 'notComplete') || ''}>
              {text === 1 && '未准备'}
              {text === 2 && '准备完成'}
              {text === 3 && '进行中'}
              {text === 4 && '完成'}
            </span>
          ),
        },
        {
          title: '操作',
          width: '20%',
          render: item => (
            <Fragment>
              {(item.state === 3 || item.state === 4 || item.state === 5) && (
                <Link to={`/assets/airdrop/detail/${item.airdropId}`}>
                  <span>详情</span>
                </Link>
              )}
              {(item.state === 1 || item.state === 2) && (
                <Link to={`/assets/airdrop/edit/${item.airdropId}`}>
                  <span>编辑</span>
                </Link>
              )}
              {(item.state === 1 || item.state === 2) && (
                <span onClick={() => this.showConfirm(item.airdropId)}>
                  删除
                </span>
              )}
            </Fragment>
          ),
        },
      ],
    }
  }

  componentDidMount() {
    const { defaultSkip, defaultLimit } = this.state
    const { dropStore } = this.props
    const { getDropList } = dropStore
    let currentCardAddress = localStorage.getItem('currentCardAddress')
    getDropList(currentCardAddress, defaultSkip, defaultLimit)
  }

  doCreateDrop = async () => {
    const { history, dropStore } = this.props
    const { newAirDrop } = dropStore
    const currentCardAddress = localStorage.getItem('currentCardAddress')
    let airdropId = await newAirDrop(currentCardAddress)
    history.push(`/assets/airdrop/new/${airdropId}`)
  }

  showConfirm = airdropId => {
    confirm({
      title: '确定删除?',
      content: '可能的影响',
      onOk: () => {
        this.doDelAirdrop(airdropId)
      },
      onCancel: () => {},
    })
  }

  doDelAirdrop = async airdropId => {
    const { deleteAirdrop, getDropList } = this.props.dropStore
    let res = await deleteAirdrop(airdropId)
    if (res.deleteAirdrop) {
      let currentCardAddress = localStorage.getItem('currentCardAddress')
      const { defaultSkip, defaultLimit } = this.state
      getDropList(currentCardAddress, defaultSkip, defaultLimit)
    } else {
      message.error('删除失败')
    }
  }

  changePageDropList = current => {
    const { defaultLimit } = this.state
    const { dropStore } = this.props
    const { getDropList } = dropStore
    let currentCardAddress = localStorage.getItem('currentCardAddress')
    getDropList(currentCardAddress, (current - 1) * defaultLimit, defaultLimit)
  }

  render() {
    const { dropStore } = this.props
    const { dropList, dropListTotal } = dropStore
    return (
      <div className="droplist">
        <Card className="contain">
          <div className="title">
            <h1>空投列表</h1>
            <Button onClick={this.doCreateDrop}>新建空投</Button>
          </div>
          <div className="table">
            <Table
              rowKey={item => item.airdropId}
              columns={this.state.col_drop}
              dataSource={dropList}
              pagination={{
                total: dropListTotal,
                defaultPageSize: 10,
                onChange: this.changePageDropList,
                hideOnSinglePage: true,
              }}
            />
          </div>
        </Card>
      </div>
    )
  }
}

export default DropList
