import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import { Row, Col, Card, Button } from 'antd'
import './index.less'

import personal from '../../../images/verify-personal.png'
import enterprise from '../../../images/verify-enterprise.png'

@inject('verifyStore', 'userStore')
@observer
class Verify extends Component {
  async componentDidMount() {
    const { verifyStore } = this.props
    verifyStore.reset()
  }

  nextStep = type => {
    this.props.history.push(`/assets/verify/${type}`)
  }

  renderPersonal = () => {
    return (
      <div className='box'>
        <Row>
          <Col span={14} offset={10}>
            <img className='image' src={personal} alt='' />
          </Col>
        </Row>
        <div className='content content-r'>
          <p className='title'>个人用户</p>
          <p className='item'>个人用户评判标准</p>
          <p className='item'>获得平台币奖励</p>
          <p className='item'>资产安全保障</p>
          <p className='item'>其他福利及条款</p>
        </div>
        <Button className='left' size='large' onClick={() => this.nextStep('personal')}>
          个人认证用户
        </Button>
      </div>
    )
  }

  renderEnterprise = () => {
    return (
      <div className='box'>
        <Row>
          <Col span={14} offset={10}>
            <img className='image' src={enterprise} alt='' />
          </Col>
        </Row>
        <div className='content content-b'>
          <p className='title'>企业用户</p>
          <p className='item'>企业用户评判标准</p>
          <p className='item'>获得平台币奖励</p>
          <p className='item'>资产安全保障</p>
          <p className='item'>其他福利及条款</p>
        </div>
        <Button className='right' size='large' onClick={() => this.nextStep('enterprise')}>
          企业认证用户
        </Button>
      </div>
    )
  }

  renderContent = () => {
    return (
      <Row>
        <Col span={20} offset={2}>
          <Row>
            <Col span={11}>{this.renderPersonal()}</Col>
            <Col span={11} offset={2}>
              {this.renderEnterprise()}
            </Col>
          </Row>
        </Col>
      </Row>
    )
  }

  render() {
    return (
      <div className='verify'>
        <Card>
          <h1>实名认证</h1>
          <div className='content-box'>{this.renderContent()}</div>
        </Card>
      </div>
    )
  }
}

export default Verify
