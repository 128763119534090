import React, { Component } from 'react'
import { Provider } from 'mobx-react'

import * as stores from './store'
import Layout from './layout'
import { createHashHistory } from 'history'

import { LocaleProvider } from 'antd'
import zh_CN from 'antd/lib/locale-provider/zh_CN'

import './App.css'

// Create hashHistory
const history = createHashHistory()

class App extends Component {
  render() {
    return (
      <LocaleProvider locale={zh_CN}>
        <Provider {...stores}>
          <div className='App'>
            <Layout history={history} />
          </div>
        </Provider>
      </LocaleProvider>
    )
  }
}

export default App
