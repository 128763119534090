import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { observer, inject } from 'mobx-react'
import { Row, Col, Steps, Button, Card, Input, Checkbox, Modal, message } from 'antd'
import './index.less'

import { private_policy } from '../../../config'
import success from '../../../images/icon-success.png'

const Step = Steps.Step

@inject('deployStore', 'userStore', 'verifyStore')
@observer
class Deploy extends Component {
  constructor(props) {
    super(props)
    this.state = {
      param_nickname: '',
      param_address: '',
      flag_address: false,
      param_name: '',
      flag_name: false,
      param_symbol: '',
      flag_symbol: false,
      param_decimal: '',
      flag_decimal: false,
      param_total: '',
      flag_total: false,
      param_check: false
    }
  }

  componentDidMount = async () => {
    const { verifyStore, deployStore } = this.props
    const { getVerificationStatus } = verifyStore
    const { setDeployCardInfo } = deployStore
    const param_cardAddress = localStorage.getItem('currentCardAddress')
    const param_cardName = localStorage.getItem('currentCardName')

    let authenticationStatus = await getVerificationStatus()
    if (authenticationStatus === 1) {
      this.props.history.push('/assets/deploy')
    } else {
      message.info('请先完成实名认证')
      this.props.history.goBack()
    }

    await setDeployCardInfo(param_cardAddress, param_cardName)
  }

  componentWillUnmount = () => {
    this.props.deployStore.reset()
  }

  setSelectAddress = e => {
    const { userStore, deployStore } = this.props
    const { cardList } = userStore
    const { setDeployCardInfo } = deployStore
    const param_cardAddress = cardList[e].addressString
    const param_cardName = cardList[e].nickname

    setDeployCardInfo(param_cardAddress, param_cardName)
  }

  setParam = (e, type, flag) => {
    let flag_temp = true
    switch (type) {
      case 'param_name':
        let regName = /^[\u4e00-\u9fa5a-zA-Z]+$/
        if (regName.test(e.target.value)) {
          flag_temp = false
        }
        break
      case 'param_symbol':
        let regSymbol = /^[\u4e00-\u9fa5a-zA-Z0-9]{2,4}$/
        if (regSymbol.test(e.target.value)) {
          flag_temp = false
        }
        break
      case 'param_decimal':
        let regDecimal = /^(0|[1-9][0-9]*)$/
        if (regDecimal.test(e.target.value) && Number(e.target.value) <= 18) {
          flag_temp = false
        }
        break
      case 'param_total':
        let regTotal = /^[0-9]+$/
        if (
          regTotal.test(e.target.value) &&
          Number(e.target.value) <= 10000000000000 &&
          Number(e.target.value) > 0
        ) {
          flag_temp = false
        }
        break
      default:
        break
    }
    this.setState({
      [type]: e.target.value,
      [flag]: flag_temp
    })
  }

  check = () => {
    const { param_name, param_symbol, param_decimal, param_total } = this.state
    let temp = false
    if (!param_name) {
      this.setState({
        flag_name: true
      })
      temp = true
    }
    /* symbol */
    let regSymbol = /^[\u4e00-\u9fa5a-zA-Z0-9]{3,4}$/
    if (!param_symbol || !regSymbol.test(param_symbol)) {
      this.setState({
        flag_symbol: true
      })
      temp = true
    }
    /* decimal */
    if (!param_decimal) {
      this.setState({
        flag_decimal: true
      })
      temp = true
    }
    /* total */
    if (!param_total || Number(param_total) > 10000000000000) {
      this.setState({
        flag_total: true
      })
      temp = true
    }
    if (!temp) {
      this.doToConfirm()
    }
  }

  doToConfirm = async () => {
    if (
      !this.state.flag_name &&
      !this.state.flag_symbol &&
      !this.state.flag_decimal &&
      !this.state.flag_total
    ) {
      this.props.deployStore.toConfirm()
    }
  }

  doToPublish = async () => {
    const { param_name, param_symbol, param_decimal, param_total } = this.state
    const { param_cardAddress } = this.props.deployStore
    let param = {
      publishAddress: param_cardAddress,
      managerAddress: param_cardAddress,
      assetName: param_name,
      assetSymbol: param_symbol,
      decimals: param_decimal,
      totalSupply: param_total
    }
    await this.props.deployStore.doPublish(param)
  }

  checkbox = e => {
    this.setState({
      param_check: e.target.checked
    })
  }

  handleOk = () => {
    this.props.deployStore.hideTerms()
  }

  handleCancel = () => {
    this.props.deployStore.hideTerms()
  }

  renderSteps = () => {
    const { deployStore } = this.props
    const { current_deploy } = deployStore
    return (
      <div className='row-steps'>
        <Steps labelPlacement='vertical' current={current_deploy}>
          <Step title='设置参数' />
          <Step title='确认信息' />
          <Step title='发布状态' />
        </Steps>
      </div>
    )
  }

  renderContent = () => {
    const { deployStore } = this.props
    const { showTerms, param_cardAddress, param_cardName } = deployStore
    return (
      <div className='content'>
        <Row>
          <Col span={3} offset={3}>
            <div className='label'>转入地址</div>
          </Col>
          <Col span={15}>
            <div className='input-box'>
              <div className='addr-name'>{param_cardName}</div>
              <Input disabled placeholder='请选择您的付款地址' value={param_cardAddress} />
            </div>
            <div className='tip'>{this.state.flag_address && '请选择资产发布到的账户地址。'}</div>
          </Col>
        </Row>
        <Row>
          <Col span={3} offset={3}>
            <div className='label'>资产名称</div>
          </Col>
          <Col span={15}>
            <div className='input-box'>
              <Input
                placeholder='请输入您的资产名称'
                onChange={e => this.setParam(e, 'param_name', 'flag_name')}
                value={this.state.param_name}
              />
            </div>
            <div className='tip'>
              {this.state.flag_name && '资产名称，只接受中文字符、英文字母。'}
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={3} offset={3}>
            <div className='label'>资产符号</div>
          </Col>
          <Col span={15}>
            <div className='input-box'>
              <Input
                placeholder='请输入资产符号'
                onChange={e => this.setParam(e, 'param_symbol', 'flag_symbol')}
                value={this.state.param_symbol}
              />
            </div>
            <div className='tip'>
              {this.state.flag_symbol &&
                '资产符号，只接受3-4个中英文、数字字符（例如ETH、BTC），不能使用空格。'}
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={3} offset={3}>
            <div className='label'>小数位</div>
          </Col>
          <Col span={15}>
            <div className='input-box'>
              <Input
                placeholder='请输入资产小数位'
                onChange={e => this.setParam(e, 'param_decimal', 'flag_decimal')}
                value={this.state.param_decimal}
              />
            </div>
            <div className='tip'>
              {this.state.flag_decimal && '小数位数，只接受0-18位，18位是最常见的做法。'}
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={3} offset={3}>
            <div className='label'>资产总量</div>
          </Col>
          <Col span={15}>
            <div className='input-box'>
              <Input
                placeholder='请输入资产总量'
                onChange={e => this.setParam(e, 'param_total', 'flag_total')}
                value={this.state.param_total}
              />
            </div>
            <div className='tip'>
              {this.state.flag_total &&
                '资产总量，为需要生产的Token总量，最小值为1，最大值为10000000000000。'}
            </div>
          </Col>
        </Row>
        <div className='btn-box'>
          <div className='check-box'>
            <Checkbox onChange={this.checkbox} checked={this.state.param_check}>
              我已仔细阅读并同意
            </Checkbox>
            <span className='terms' onClick={showTerms}>
              服务隐私条款
            </span>
          </div>
          <Button size='large' disabled={!this.state.param_check} onClick={this.check}>
            下一步
          </Button>
        </div>
      </div>
    )
  }

  renderConfirm = () => {
    const { deployStore } = this.props
    const { toPrevious, loading, param_cardAddress, param_cardName } = deployStore
    return (
      <div className='content content-2'>
        <Row>
          <Col span={3} offset={3}>
            <div className='label'>资产类型</div>
          </Col>
          <Col span={15}>
            <span className='value'>固定资产</span>
          </Col>
        </Row>
        <Row>
          <Col span={3} offset={3}>
            <div className='label'>转入地址</div>
          </Col>
          <Col span={15}>
            <div className='value'>
              <span className='blank'>{param_cardName}</span>
              <span>{param_cardAddress}</span>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={3} offset={3}>
            <div className='label'>资产名称</div>
          </Col>
          <Col span={15}>
            <div className='value'>{this.state.param_name}</div>
          </Col>
        </Row>
        <Row>
          <Col span={3} offset={3}>
            <div className='label'>资产符号</div>
          </Col>
          <Col span={15}>
            <div className='value'>{this.state.param_symbol}</div>
          </Col>
        </Row>
        <Row>
          <Col span={3} offset={3}>
            <div className='label'>小数位</div>
          </Col>
          <Col span={15}>
            <div className='value'>{this.state.param_decimal}</div>
          </Col>
        </Row>
        <Row>
          <Col span={3} offset={3}>
            <div className='label'>资产总量</div>
          </Col>
          <Col span={15}>
            <div className='value'>{this.state.param_total}</div>
          </Col>
        </Row>
        <Row className='btn-box-2'>
          <Col span={3} offset={3}>
            <Button size='large' onClick={toPrevious}>
              返回
            </Button>
          </Col>
          <Col className='submit-box' span={12}>
            <Button className='submit' size='large' onClick={this.doToPublish} loading={loading}>
              确认发布
            </Button>
          </Col>
        </Row>
      </div>
    )
  }

  renderTerms = () => {
    const { deployStore } = this.props
    const { isTermsShow } = deployStore
    return (
      <Modal
        title='服务隐私条款'
        visible={isTermsShow}
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        footer={null}
        width={1000}
      >
        <pre>{private_policy}</pre>
      </Modal>
    )
  }

  renderSuccess = () => {
    const { deployStore } = this.props
    const { assetsInfo } = deployStore
    return (
      <div className='content content-3'>
        <img src={success} alt='success' />
        <p>
          您已成功发布资产，{assetsInfo.publishStatus === 2 && '资产正在打包中，'}
          <Link to='/assets/home'>返回</Link> 首页立即查看。
        </p>
      </div>
    )
  }

  render() {
    const { deployStore } = this.props
    const { current_deploy } = deployStore
    return (
      <div className='deploy'>
        <Card>
          <h1>发布资产</h1>
          {this.renderSteps()}
          {current_deploy === 0 && this.renderContent()}
          {current_deploy === 1 && this.renderConfirm()}
          {current_deploy === 2 && this.renderSuccess()}
        </Card>
        {this.renderTerms()}
      </div>
    )
  }
}

export default Deploy
