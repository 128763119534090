import { observable, action } from 'mobx'
import { query, mutation, subscription } from '../../common/apollo'
import { message } from 'antd'
import { getFormatDate } from '../../common/tool'
import moment from 'moment'

import { config } from '../../config'

export default class AssetsStore {
  /* obserable */
  @observable
  assetsList = []

  @observable
  assetsTotal = 0

  @observable
  records = []

  @observable
  isLoading = true

  @observable
  recordsTotal = 0

  @observable
  assetsDetailInfo = {}

  @observable
  assetsDetailHoldingList = []

  @observable
  assetsDetailHoldingTotal = 0

  @observable
  assetsDetailLogList = []

  @observable
  assetsDetailLogTotal = 0
  /* mint */
  @observable
  mintDetailInfo = {}

  @observable
  mintModalStatus = false

  @observable
  mintScanStatus = 0

  @observable
  mintTxId = ''
  /* burn */
  @observable
  burnDetailInfo = {}

  @observable
  burnModalStatus = false

  @observable
  burnScanStatus = 0

  @observable
  burnTxId = ''
  /* move */
  @observable
  moveDetailInfo = {}

  @observable
  moveModalStatus = false

  @observable
  moveScanStatus = 0

  @observable
  moveTxId = ''
  /* forbidden */
  @observable
  forbiddenDetailInfo = {}

  @observable
  forbiddenModalStatus = false

  @observable
  forbiddenScanStatus = 0

  @observable
  forbiddenTxId = ''

  /* action */
  @action
  sendVerificationCode = async () => {
    const body = `mutation sendVerificationCode($phoneNumber: String!)`
    const variables = {
      phoneNumber: this.param_phone
    }
    await mutation(body, variables)
  }

  @action
  getAssetsList = async (address, skip, limit) => {
    const variables = {
      address: address,
      skip: skip,
      limit: limit
    }
    const body = `
      query getAssetsList(
        $address: String!
        $skip: Int
        $limit: Int
      ){
        assetsOfCard(
          address:$address
          skip:$skip
          limit:$limit
        ){
          totalCount
          assetses{
            id
            assetName
            assetSymbol
            totalSupply
            publishStatus
          }
        }
      }
    `
    let res = await query(body, variables)
    if (res) {
      this.assetsTotal = res.assetsOfCard.totalCount
      this.assetsList = res.assetsOfCard.assetses
    }
  }

  @action
  tradingRecords = async (tradingAddress, skip, limit) => {
    this.isLoading = true
    const body = `
    query records(
      $tradingAddress: String!
      $limit: Int
      $skip: Int
    ){
      tradingRecords(
        tradingAddress:$tradingAddress
        limit:$limit
        skip:$skip
      ){
        totalCount
        records{
          txHash
          createdAt
          blockNumber
          tradingType
          tradingStatus
          amount
          symbol
          decimals
          from
          to
          otherAddress
          contractAddress
          comment
          amountDecimalsClip
          amountDecimals
        }
      }
    }`

    const variables = {
      tradingAddress: tradingAddress,
      limit: 10,
      skip: skip
    }
    let res = await query(body, variables)
    if (res) {
      let arr = res.tradingRecords.records
      for (let i = 0; i < arr.length; i++) {
        arr[i]['transTime'] = getFormatDate(parseInt(arr[i]['createdAt']))
        switch (arr[i]['tradingType']) {
          case 0:
            arr[i]['tradingType'] = '发布资产'
            break
          case 1:
            arr[i]['tradingType'] = '付款'
            break
          case 2:
            arr[i]['tradingType'] = '收款'
            break
          case 3:
            arr[i]['tradingType'] = '资产增发'
            break
          case 4:
            arr[i]['tradingType'] = '资产销毁'
            break
          default:
            break
        }
      }
      this.isLoading = false
      this.records = arr
      this.recordsTotal = res.tradingRecords.totalCount
      return arr
    }
  }

  getAssetsDetail = async (cardId, assetId) => {
    const variables = {
      cardId: cardId,
      id: assetId
    }
    const body = `
    query detail(
      $cardId: String
      $id: String!
    ){
      assets(
        cardId:$cardId
        id:$id
      ){
        id
        assetName
        assetSymbol
        totalSupply
        holdingNum
        createdAt
        publishAddress
        managerAddress
        contractAddress
        updatedAt
        mintState
        burnState
        transferOwnerState
        pauseState
      }
    }`
    let res = await query(body, variables)
    if (res) {
      let createTimestamp = res.assets.createdAt
      let updateTimestamp = res.assets.updatedAt
      res.assets.createdAtFormat = moment.unix(createTimestamp).format('YYYY-MM-DD HH:mm:ss') || '-'
      res.assets.updatedAtFormat = moment.unix(updateTimestamp).format('YYYY-MM-DD HH:mm:ss') || '-'
      this.assetsDetailInfo = res.assets
    }
  }

  @action
  doDelAssets = async id => {
    const variables = {
      id: id
    }
    const body = `
      mutation delAssets(
        $id: String!
      ){
        deleteInvaildAssets(
          id:$id
        ){
          id
        }
      }
    `
    let res = await mutation(body, variables)
    return res
  }

  @action
  getDetailHoldingList = async (assetId, skip, limit, accountAddress) => {
    const variables = {
      assetId: assetId,
      skip: skip,
      limit: limit,
      accountAddress: accountAddress
    }
    const body = `
      query getHolding(
        $assetId: String!
        $skip: Int!
        $limit: Int!
        ${accountAddress && '$accountAddress: String'}
      ){
        holdingConditions(
          assetId:$assetId
          skip:$skip
          limit:$limit
          ${accountAddress && 'accountAddress: $accountAddress'}
        ){
          totalCount
          holders {
            id
            address
            holdingNum
            rate
            assetId
          }
        }
      }`

    let res = await query(body, variables)
    if (res) {
      this.assetsDetailHoldingTotal = res.holdingConditions.totalCount
      this.assetsDetailHoldingList = res.holdingConditions.holders
    }
  }

  @action
  getDetailLogList = async (assetId, skip, limit) => {
    const variables = {
      assetId: assetId,
      skip: skip,
      limit: limit
    }
    const body = `
      query getDetailLogList(
        $assetId: String!
        $skip: Int!
        $limit: Int!
      ){
        operationLog(
          assetId:$assetId
          skip:$skip
          limit:$limit
        ){
          totalCount
          operations{
            id
            time
            address
            content
            assetId
          }
        }
      }
    `
    let res = await query(body, variables)
    if (res) {
      for (let item of res.operationLog.operations) {
        item.time = moment.unix(item.time).format('YYYY-MM-DD HH:mm:ss')
      }
      this.assetsDetailLogTotal = res.operationLog.totalCount
      this.assetsDetailLogList = res.operationLog.operations
    }
  }

  /* mint */
  @action
  getMintDetail = async (cardId, assetId) => {
    const variables = {
      cardId: cardId,
      id: assetId
    }
    const body = `
    query detail(
      $cardId: String
      $id: String!
    ){
      assets(
        cardId:$cardId
        id:$id
      ){
        id
        type
        assetName
        assetSymbol
        decimals
        totalSupply
        managerAddress
        holdingNum
      }
    }`

    let res = await query(body, variables)
    if (res) {
      this.mintDetailInfo = res.assets
    }
  }

  @action
  doStartMint = async assetId => {
    let that = this
    const variables = null
    const body = `
      mutation getTxid{
        startTrading
      }`
    let res = await mutation(body, variables)
    if (res) {
      this.mintTxId = res.startTrading
      let wsVariables = {
        txId: res.startTrading
      }
      let wsBody = `
        subscription checkTrading(
          $txId: String!
        ) {
          checkTrading(
            txId: $txId
          )
        }
      `
      subscription(wsBody, wsVariables).then(res => {
        res.subscribe({
          next(data) {
            let checkTrading = data.data.checkTrading
            if (checkTrading === 2) {
              that.hideMintModal()
              message.success('增发成功')
              setTimeout(
                window.location.replace(`${config.host_route}/#/assets/home/detail/${assetId}`),
                1000
              )
            }
            that.mintScanStatus = checkTrading
          }
        })
      })
    }
  }

  @action
  showMintModal = () => {
    this.mintModalStatus = true
  }

  @action
  hideMintModal = () => {
    this.mintScanStatus = 0
    this.mintModalStatus = false
  }
  /* burn */
  @action
  getBurnDetail = async (cardId, assetId) => {
    const info_variables = {
      cardId: cardId,
      id: assetId
    }
    const info_body = `
    query detail(
      $cardId: String
      $id: String!
    ){
      assets(
        cardId:$cardId
        id:$id
      ){
        assetSymbol
        managerAddress
        contractAddress
      }
    }`

    let info_res = await query(info_body, info_variables)
    const managerAddress = info_res.assets.managerAddress
    const contractAddress = info_res.assets.contractAddress
    /* get balance */
    const variables = {
      address: managerAddress,
      contractAddress: contractAddress
    }
    const body = `
      query getBurnDetail(
        $address: String!
        $contractAddress: String!
      ){
        balanceOf(
          address:$address
          contractAddress:$contractAddress
        ){
          id
          holdingNum
        }
      }
    `
    let res = await query(body, variables)
    if (res) {
      this.burnDetailInfo = res.balanceOf
      this.burnDetailInfo['assetSymbol'] = info_res.assets.assetSymbol
    }
  }

  @action
  doStartBurn = async assetId => {
    let that = this
    const variables = null
    const body = `
      mutation getTxid{
        startTrading
      }`
    let res = await mutation(body, variables)
    if (res) {
      this.burnTxId = res.startTrading
      let wsVariables = {
        txId: res.startTrading
      }
      let wsBody = `
        subscription checkTrading(
          $txId: String!
        ) {
          checkTrading(
            txId: $txId
          )
        }
      `
      subscription(wsBody, wsVariables).then(res => {
        res.subscribe({
          next(data) {
            let checkTrading = data.data.checkTrading
            if (checkTrading === 2) {
              that.hideBurnModal()
              message.success('销毁成功')
              setTimeout(
                window.location.replace(`${config.host_route}/#/assets/home/detail/${assetId}`),
                1000
              )
            }
            that.burnScanStatus = checkTrading
          }
        })
      })
    }
  }

  @action
  showBurnModal = () => {
    this.burnModalStatus = true
  }

  @action
  hideBurnModal = () => {
    this.burnScanStatus = 0
    this.burnModalStatus = false
  }
  /* move */
  @action
  getMoveDetail = async (cardId, assetId) => {
    const variables = {
      cardId: cardId,
      id: assetId
    }
    const body = `
    query detail(
      $cardId: String
      $id: String!
    ){
      assets(
        cardId:$cardId
        id:$id
      ){
          managerAddress
        }
      }
    `
    let res = await query(body, variables)
    if (res) {
      this.moveDetailInfo = res.assets
    }
  }

  @action
  doStartMove = async assetId => {
    let that = this
    const variables = null
    const body = `
      mutation getTxid{
        startTrading
      }`
    let res = await mutation(body, variables)
    if (res) {
      this.moveTxId = res.startTrading
      let wsVariables = {
        txId: res.startTrading
      }
      let wsBody = `
        subscription checkTrading(
          $txId: String!
        ) {
          checkTrading(
            txId: $txId
          )
        }
      `
      subscription(wsBody, wsVariables).then(res => {
        res.subscribe({
          next(data) {
            let checkTrading = data.data.checkTrading
            if (checkTrading === 2) {
              that.hideMoveModal()
              message.success('转移成功')
              setTimeout(
                window.location.replace(`${config.host_route}/#/assets/home/detail/${assetId}`),
                1000
              )
            }
            that.moveScanStatus = checkTrading
          }
        })
      })
    }
  }

  @action
  showMoveModal = () => {
    this.moveModalStatus = true
  }

  @action
  hideMoveModal = () => {
    this.moveScanStatus = 0
    this.moveModalStatus = false
  }
  /* forbidden */
  @action
  doStartForbidden = async () => {
    let that = this
    const variables = null
    const body = `
      mutation getTxid{
        startTrading
      }`
    let res = await mutation(body, variables)
    if (res) {
      this.forbiddenTxId = res.startTrading
      let wsVariables = {
        txId: res.startTrading
      }
      let wsBody = `
        subscription checkTrading(
          $txId: String!
        ) {
          checkTrading(
            txId: $txId
          )
        }
      `
      subscription(wsBody, wsVariables).then(res => {
        res.subscribe({
          next(data) {
            let checkTrading = data.data.checkTrading
            if (checkTrading === 2) {
              that.hideForbiddenModal()
              message.success('禁用/启用成功')
            }
            that.forbiddenScanStatus = checkTrading
          }
        })
      })
    }
  }

  @action
  showForbiddenModal = () => {
    this.forbiddenModalStatus = true
  }

  @action
  hideForbiddenModal = () => {
    this.forbiddenScanStatus = 0
    this.forbiddenModalStatus = false
  }
}
