import { observable, action } from 'mobx'
import { mutation } from '../../common/apollo'

export default class DeployStore {
  /* obserable */
  @observable
  current_deploy = 0

  @observable
  isTermsShow = false

  @observable
  wallet_list = []

  @observable
  assetsInfo = ''

  @observable
  loading = false
  /* --- card --- */
  @observable
  param_cardAddress = ''

  @observable
  param_cardName = ''

  /* action */
  @action
  toConfirm = () => {
    this.current_deploy++
  }

  @action
  doPublish = async e_param => {
    this.loading = true
    const body = `
      mutation doDeploy(
        $publishAddress: String!
        $managerAddress: String!
        $assetName: String!
        $assetSymbol: String!
        $decimals: Int!
        $totalSupply: String!
      ) {
        publishAssets(
          type: 1
          publishAddress: $publishAddress
          managerAddress: $managerAddress
          assetName: $assetName
          assetSymbol: $assetSymbol
          decimals: $decimals
          totalSupply: $totalSupply
        ){
          id
          publishStatus
        }
      }
    `
    const variables = {
      publishAddress: e_param.publishAddress,
      managerAddress: e_param.managerAddress,
      assetName: e_param.assetName,
      assetSymbol: e_param.assetSymbol,
      decimals: Number(e_param.decimals),
      totalSupply: e_param.totalSupply
    }
    let res = await mutation(body, variables)
    this.loading = false
    if (res) {
      this.assetsInfo = res.publishAssets
      this.current_deploy++
    }
  }

  @action
  toPrevious = () => {
    this.current_deploy--
  }

  @action
  reset = () => {
    this.current_deploy = 0
  }

  @action
  showTerms = () => {
    this.isTermsShow = true
  }

  @action
  hideTerms = () => {
    this.isTermsShow = false
  }

  @action
  setDeployCardInfo = (currentCardAddress, currentCardName) => {
    this.param_cardName = currentCardName
    this.param_cardAddress = currentCardAddress
  }
}
