import React, { Component } from 'react'
import { Row } from 'antd'
// eslint-disable-next-line
import style from './index.less'

class Tip extends Component {
  render() {
    return (
      <div className='tip'>
        <Row>
          <h1>here is tip</h1>
        </Row>
      </div>
    )
  }
}

export default Tip
