import UserStore from './User'
import AssetsStore from './Assets'
import VerifyStore from './Verify'
import DeployStore from './Deploy'
import TxsStore from './Txs'
import DropStore from './AirDrop'

const userStore = new UserStore()
const assetsStore = new AssetsStore()
const verifyStore = new VerifyStore()
const deployStore = new DeployStore()
const txsStore = new TxsStore()
const dropStore = new DropStore()

export { userStore, assetsStore, verifyStore, deployStore, txsStore, dropStore }
