import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import './index.less'

class SideBar extends Component {
  componentDidMount() {}

  render() {
    return (
      <div className='bar'>
        <NavLink
          to={{
            pathname: '/assets/home'
          }}
        >
          <div className='bar-item'>
            <div className='icon icon-0' />
            <span>资产详情</span>
          </div>
        </NavLink>
        <NavLink
          to={{
            pathname: '/assets/trans'
          }}
        >
          <div className='bar-item'>
            <div className='icon icon-1' />
            <span>转账</span>
          </div>
        </NavLink>
        <NavLink
          to={{
            pathname: '/assets/deploy'
          }}
        >
          <div className='bar-item'>
            <div className='icon icon-2' />
            <span>发布资产</span>
          </div>
        </NavLink>
        <NavLink
          to={{
            pathname: '/assets/manage'
          }}
        >
          <div className='bar-item'>
            <div className='icon icon-3' />
            <span>账户管理</span>
          </div>
        </NavLink>
        <NavLink
          to={{
            pathname: '/assets/verify'
          }}
        >
          <div className='bar-item'>
            <div className='icon icon-4' />
            <span>实名认证</span>
          </div>
        </NavLink>
        <NavLink
          to={{
            pathname: '/assets/airdrop'
          }}
        >
          <div className='bar-item'>
            <div className='icon icon-5' />
            <span>营销方案</span>
          </div>
        </NavLink>
      </div>
    )
  }
}

export default SideBar
