import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import { Card } from 'antd'
import { Link } from 'react-router-dom'

import './index.less'
import drop from '../../../images/drop.png'

@inject('dropStore')
@observer
class Drop extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {}

  render() {
    return (
      <div className='drop'>
        <Card className='contain'>
          <h1>营销方案</h1>
          <div className='item'>
            <Link
              to={{
                pathname: '/assets/airdrop/list'
              }}
            >
              <div className='lot'>
                <img src={drop} alt='drop' />
                <span>批量空投</span>
              </div>
            </Link>
          </div>
        </Card>
      </div>
    )
  }
}

export default Drop
