import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import { Card, Row, Col, Input, Button, Modal } from 'antd'
import QRCode from 'qrcode.react'

import './index.less'

import icon_l from '../../../images/pop-0.png'
import icon_r from '../../../images/pop-1.png'
import { QR } from '../../../config'

const { confirm } = Modal

@inject('userStore', 'assetsStore', 'verifyStore')
@observer
class DetailBurn extends Component {
  constructor(props) {
    super(props)
    this.state = {
      param_amount: '',
      flag_amount: false,
      cardAddress: '',
      assetId: ''
    }
  }

  componentDidMount() {
    let assetId = this.props.match.params.id
    let cardAddress = localStorage.getItem('currentCardAddress')
    this.props.assetsStore.getBurnDetail(cardAddress, assetId)
    this.setState({
      assetId: assetId,
      cardAddress: cardAddress
    })
  }

  renderTipBar = () => {
    return (
      <div className='tip-bar'>
        <Row>
          <Col span={21} offset={3}>
            温馨提示：资产一旦销毁将无法找回，请谨慎操作
          </Col>
        </Row>
      </div>
    )
  }

  renderForm = () => {
    const { burnDetailInfo } = this.props.assetsStore
    const { cardAddress } = this.state
    return (
      <div className='form'>
        <Row>
          <Col span={3} offset={3}>
            <div className='label'>销毁地址</div>
          </Col>
          <Col span={15}>
            <span className='value'>{cardAddress}</span>
          </Col>
        </Row>
        <Row>
          <Col span={3} offset={3}>
            <div className='label'>持有资产</div>
          </Col>
          <Col span={15}>
            <span className='value'>{`${burnDetailInfo.holdingNum} ${
              burnDetailInfo.assetSymbol
            }`}</span>
          </Col>
        </Row>
        <Row>
          <Col span={3} offset={3}>
            <div className='label'>销毁数量</div>
          </Col>
          <Col span={15}>
            <div className='input-box'>
              <Input
                placeholder={`可销毁余量为${
                  Number(burnDetailInfo.holdingNum) === 0 ? 0 : `1~${burnDetailInfo.holdingNum}`
                }`}
                value={this.state.param_amount}
                onChange={this.setParamAmount}
              />
              <Button onClick={this.setParamAmountAll}>全部</Button>
            </div>
            <div className='tip'>{this.state.flag_amount && '请正确填写销毁数量'}</div>
          </Col>
        </Row>
        <Row>
          <Col offset={6}>
            <Button className='ant-btn-long' size='large' onClick={this.check}>
              确认
            </Button>
          </Col>
        </Row>
      </div>
    )
  }

  setParamAmount = e => {
    const { burnDetailInfo } = this.props.assetsStore
    let temp = false
    let value = e.target.value
    let regAmount = /^(0|[1-9][0-9]*)$/
    if (
      !regAmount.test(value) ||
      Number(value) < 0 ||
      Number(value) > Number(burnDetailInfo.holdingNum)
    ) {
      temp = true
    }
    this.setState({
      param_amount: value,
      flag_amount: temp
    })
  }

  setParamAmountAll = () => {
    const { burnDetailInfo } = this.props.assetsStore
    this.setState({
      param_amount: Number(burnDetailInfo.holdingNum),
      flag_amount: false
    })
  }

  check = () => {
    if (!this.state.param_amount) {
      this.setState({
        flag_amount: true
      })
      return
    }
    if (this.state.flag_amount) {
      return
    }
    this.doConfirm()
  }

  doConfirm = () => {
    confirm({
      title: '确定销毁该资产?',
      content: '资产一旦销毁将无法找回，请谨慎操作。',
      onOk: () => {
        this.doShowModal()
      },
      onCancel: () => {}
    })
  }

  doShowModal = () => {
    this.props.assetsStore.doStartBurn(this.state.assetId)
    this.props.assetsStore.showBurnModal()
  }

  renderModal = () => {
    const { burnModalStatus, hideBurnModal, burnScanStatus, burnTxId } = this.props.assetsStore
    const { assetId, cardAddress, param_amount } = this.state
    let qrInfo = QR.burn(burnTxId, assetId, cardAddress, param_amount)
    return (
      <Modal
        title='销毁签名授权'
        visible={burnModalStatus}
        onOk={hideBurnModal}
        onCancel={hideBurnModal}
        footer={null}
        closable={false}
      >
        <Row>
          <Col className='top' span={10} offset={7}>
            <img src={icon_l} alt='' />
            <div className='dashed' />
            <img src={icon_r} alt='' />
          </Col>
        </Row>
        <Row>
          <Col className='cen'>请打开鲸矿APP扫码</Col>
        </Row>
        <Row>
          <Col className='btm' span={10} offset={7}>
            <div className='box'>
              {burnScanStatus === 1 ? (
                <div className='mask'>
                  <span className='pending'>等待中...</span>
                </div>
              ) : null}
              <div className='qrcode-box'>
                <QRCode value={qrInfo} size={180} renderAs={'svg'} />
              </div>
            </div>
          </Col>
        </Row>
      </Modal>
    )
  }

  render() {
    return (
      <div className='burn'>
        <Card className='contain'>
          <h1>资产详情/资产销毁</h1>
          {this.renderTipBar()}
          {this.renderForm()}
        </Card>
        {this.renderModal()}
      </div>
    )
  }
}

export default DetailBurn
