import React, { Component } from 'react'
import { HashRouter as Router, Route, Switch } from 'react-router-dom'
import { Row, Col } from 'antd'
import './index.less'

import { Home, Detail, DetailBurn, DetailMint, DetailMove } from '../pages/Assets'
import { Deploy } from '../pages/Deploy'
import Manage from '../pages/Manage'
import Trans from '../pages/Txs'
import { Verify, Personal, Enterprise } from '../pages/Verify'
import { Drop, DropDetail, DropList, DropNew } from '../pages/Airdrop'

import SideBar from '../components/SideBar'
import SideCard from '../components/SideCard'

class PageRouter extends Component {
  componentDidMount() {
    if (this.props.history.location.pathname === '/assets') {
      this.props.history.replace('/assets/home')
    }
  }

  componentWillReceiveProps() {
    if (this.props.history.location.pathname === '/assets') {
      this.props.history.replace('/assets/home')
    }
  }

  render() {
    return (
      <div>
        <Row>
          <Col className='side' span={5}>
            <SideCard />
            <SideBar />
          </Col>
          <Col span={19}>
            <Router>
              <Switch>
                <Route exact path='/assets/home' component={Home} />
                <Route exact path='/assets/home/detail/:id' component={Detail} />
                <Route exact path='/assets/home/detail/:id/mint' component={DetailMint} />
                <Route exact path='/assets/home/detail/:id/burn' component={DetailBurn} />
                <Route exact path='/assets/home/detail/:id/move' component={DetailMove} />
                <Route exact path='/assets/trans' component={Trans} />
                <Route exact path='/assets/deploy' component={Deploy} />
                <Route exact path='/assets/manage' component={Manage} />
                <Route exact path='/assets/verify' component={Verify} />
                <Route exact path='/assets/verify/personal' component={Personal} />
                <Route exact path='/assets/verify/enterprise' component={Enterprise} />
                <Route exact path='/assets/airdrop' component={Drop} />
                <Route exact path='/assets/airdrop/list' component={DropList} />
                <Route exact path='/assets/airdrop/new/:id' component={DropNew} />
                <Route exact path='/assets/airdrop/edit/:id' component={DropNew} />
                <Route exact path='/assets/airdrop/detail/:id' component={DropDetail} />
              </Switch>
            </Router>
          </Col>
        </Row>
      </div>
    )
  }
}
export default PageRouter
