import React, { Component, Fragment } from 'react'
import { observer, inject } from 'mobx-react'
import { Link } from 'react-router-dom'
import { Card, Row, Col, Button, Tabs, Table, Modal, Input, Popover } from 'antd'
import QRCode from 'qrcode.react'

import './index.less'

import icon_l from '../../../images/pop-0.png'
import icon_r from '../../../images/pop-1.png'
import { QR } from '../../../config'

const { confirm } = Modal
const { TabPane } = Tabs
const col_tab1 = [
  {
    title: '编号',
    dataIndex: 'id',
    width: '10%'
  },
  {
    title: '地址',
    dataIndex: 'address',
    width: '50%',
    render: text => <span>{text}</span>
  },
  {
    title: '持有数量',
    dataIndex: 'holdingNum',
    width: '30%'
  },
  {
    title: '持有百分比',
    dataIndex: 'rate',
    width: '10%'
  },
]
const col_tab2 = [
  {
    title: '时间',
    dataIndex: 'time',
    width: '20%'
  },
  {
    title: '管理员地址',
    dataIndex: 'address',
    width: '30%',
    render: text => (
      <Popover content={text}>
        <span>{text}</span>
      </Popover>
    )
  },
  {
    title: '',
    width: '5%'
  },
  {
    title: '内容',
    dataIndex: 'content',
    width: '45%',
    render: text => (
      <Popover content={text}>
        <span>{text}</span>
      </Popover>
    )
  }
]

const MINT_STATE_ING = 1
const BURN_STATE_ING = 1
const TRANSFER_STATE_ING = 1
const PAUSE_STATE_ING = 1
const PAUSE_STATE_ENABLE = 2
const PAUSE_STATE_DISABLE = 3

@inject('assetsStore')
@observer
class Detail extends Component {
  constructor(props) {
    super(props)
    this.state = { tabKey: 1, param_search: '', assetId: '', defaultSkip: 0, defaultLimit: 10 }
  }

  componentDidMount() {
    const { assetsStore } = this.props
    const { getAssetsDetail, getDetailHoldingList, getDetailLogList } = assetsStore
    const currentAssetId = this.props.match.params.id
    const skip = 0
    const limit = 10
    const accountAddress = ''
    const currentCardAddress = localStorage.getItem('currentCardAddress')
    this.setState({
      assetId: currentAssetId
    })
    getAssetsDetail(currentCardAddress, currentAssetId)
    getDetailHoldingList(currentAssetId, skip, limit, accountAddress)
    getDetailLogList(currentAssetId, skip, limit)
  }

  renderInfo = () => {
    const { assetsDetailInfo } = this.props.assetsStore
    return (
      <Fragment>
        <Row>
          <Col span={8}>
            <span>资产名称：</span>
            <span>{assetsDetailInfo.assetName}</span>
          </Col>
          <Col span={8}>
            <span>资产符号：</span>
            <span>{assetsDetailInfo.assetSymbol}</span>
          </Col>
          <Col span={8}>
            <span>状态：</span>
            {assetsDetailInfo.pauseState === PAUSE_STATE_ING && (
              <span className='status-r'>处理中</span>
            )}
            {assetsDetailInfo.pauseState === PAUSE_STATE_ENABLE && (
              <span className='status-g'>流通中</span>
            )}
            {assetsDetailInfo.pauseState === PAUSE_STATE_DISABLE && (
              <span className='status-r'>禁止中</span>
            )}
          </Col>
        </Row>
        <Row>
          <Col span={11}>
            <div className='ellipsis-box'>
              <span>资产发起人：</span>
              <Popover content={assetsDetailInfo.publishAddress}>
                <span className='ellipsis'>{assetsDetailInfo.publishAddress}</span>
              </Popover>
            </div>
          </Col>
          <Col span={7}>
            <span>发行时间：</span>
            <span>{assetsDetailInfo.createdAtFormat}</span>
          </Col>
          <Col span={6}>
            <span>发行总量：</span>
            <span>{assetsDetailInfo.totalSupply}</span>
          </Col>
        </Row>
        <Row>
          <Col span={11}>
            <div className='ellipsis-box'>
              <span>资产管理员：</span>
              <Popover content={assetsDetailInfo.managerAddress}>
                <span className='ellipsis'>{assetsDetailInfo.managerAddress}</span>
              </Popover>
            </div>
          </Col>
          <Col span={11}>
            <div className='ellipsis-box'>
              <span>合约地址：</span>
              <Popover content={assetsDetailInfo.contractAddress}>
                <span className='ellipsis'>{assetsDetailInfo.contractAddress}</span>
              </Popover>
            </div>
          </Col>
        </Row>
        {assetsDetailInfo.managerAddress === localStorage.getItem('currentCardAddress') && (
          <Row>
            <div className='btn-box'>
              <Button
                disabled={
                  assetsDetailInfo.pauseState === PAUSE_STATE_ING ||
                  assetsDetailInfo.pauseState === PAUSE_STATE_DISABLE ||
                  assetsDetailInfo.mintState === MINT_STATE_ING ||
                  assetsDetailInfo.transferOwnerState === TRANSFER_STATE_ING
                }
              >
                <Link to={{ pathname: `/assets/home/detail/${assetsDetailInfo.id}/mint` }}>
                  资产增发
                </Link>
              </Button>
              <Button
                disabled={
                  assetsDetailInfo.pauseState === PAUSE_STATE_ING ||
                  assetsDetailInfo.pauseState === PAUSE_STATE_DISABLE ||
                  assetsDetailInfo.burnState === BURN_STATE_ING ||
                  assetsDetailInfo.transferOwnerState === TRANSFER_STATE_ING
                }
              >
                <Link to={{ pathname: `/assets/home/detail/${assetsDetailInfo.id}/burn` }}>
                  资产销毁
                </Link>
              </Button>
              <Button
                disabled={
                  assetsDetailInfo.pauseState === PAUSE_STATE_ING ||
                  assetsDetailInfo.pauseState === PAUSE_STATE_DISABLE ||
                  assetsDetailInfo.transferOwnerState === TRANSFER_STATE_ING
                }
              >
                <Link to={{ pathname: `/assets/home/detail/${assetsDetailInfo.id}/move` }}>
                  所有权转移
                </Link>
              </Button>
              <Button
                disabled={
                  assetsDetailInfo.pauseState === 1 || assetsDetailInfo.transferOwnerState === 1
                }
                onClick={() => this.showConfirm(assetsDetailInfo.id)}
              >
                {assetsDetailInfo.pauseState === PAUSE_STATE_ING && '处理中'}
                {assetsDetailInfo.pauseState === PAUSE_STATE_ENABLE && '禁止流通'}
                {assetsDetailInfo.pauseState === PAUSE_STATE_DISABLE && '启用流通'}
              </Button>
            </div>
          </Row>
        )}
      </Fragment>
    )
  }

  changePageHolding = current => {
    const { assetId, defaultLimit } = this.state
    this.props.assetsStore.getDetailHoldingList(assetId, (current - 1) * defaultLimit, defaultLimit)
  }

  changePageLog = current => {
    const { assetId, defaultLimit } = this.state
    this.props.assetsStore.getDetailLogList(assetId, (current - 1) * defaultLimit, defaultLimit)
  }

  renderTab1 = () => {
    const {
      assetsDetailInfo,
      assetsDetailHoldingList,
      assetsDetailHoldingTotal
    } = this.props.assetsStore
    return (
      <div className='table table1'>
        <Table
          rowKey={item => item.address}
          columns={col_tab1}
          dataSource={assetsDetailHoldingList}
          pagination={{
            total: assetsDetailHoldingTotal,
            defaultPageSize: 10,
            onChange: this.changePageHolding,
            hideOnSinglePage: true
          }}
        />
        <div className='info-box'>
          <span>
            资产（{assetsDetailInfo.assetSymbol}）持有地址总数：
            {assetsDetailHoldingTotal}个
          </span>
          <span>更新时间：{assetsDetailInfo.updatedAtFormat}</span>
        </div>
      </div>
    )
  }

  renderTab2 = () => {
    const { assetsStore } = this.props
    const { assetsDetailLogList, assetsDetailLogTotal } = assetsStore
    return (
      <div className='table table2'>
        <Table
          rowKey={item => item.id}
          columns={col_tab2}
          dataSource={assetsDetailLogList}
          pagination={{
            total: assetsDetailLogTotal,
            showTotal: () => {
              return `共${assetsDetailLogTotal}条日志`
            },
            defaultPageSize: 10,
            onChange: this.changePageLog,
            hideOnSinglePage: true
          }}
        />
      </div>
    )
  }

  showConfirm = id => {
    const { assetsDetailInfo } = this.props.assetsStore
    confirm({
      title: `确认${assetsDetailInfo.pauseState === 2 ? '禁止流通' : '启用流通'}该资产?`,
      onOk: () => {
        this.props.assetsStore.doStartForbidden()
        this.props.assetsStore.showForbiddenModal()
      },
      onCancel: () => {}
    })
  }

  changeTab = activeKey => {
    this.setState({
      tabKey: Number(activeKey)
    })
  }

  doSearchHolding = () => {
    const { assetId, defaultSkip, defaultLimit, param_search } = this.state
    const { getDetailHoldingList } = this.props.assetsStore
    getDetailHoldingList(assetId, defaultSkip, defaultLimit, param_search)
  }

  handlerInputAddress = e => {
    this.setState({
      param_search: e.target.value
    })
  }

  renderModal = () => {
    const {
      forbiddenModalStatus,
      hideForbiddenModal,
      forbiddenScanStatus,
      forbiddenTxId,
      assetsDetailInfo
    } = this.props.assetsStore
    let type = 4
    let title = '禁止流通签名授权'
    if (assetsDetailInfo.pauseState === 2) {
      type = 4
      title = '禁止流通签名授权'
    } else {
      type = 5
      title = '启用流通签名授权'
    }
    let qrInfo = QR.forbidden(type, forbiddenTxId, this.state.assetId)
    return (
      <Modal
        title={title}
        visible={forbiddenModalStatus}
        onOk={hideForbiddenModal}
        onCancel={hideForbiddenModal}
        footer={null}
        closable={false}
      >
        <Row>
          <Col className='top' span={10} offset={7}>
            <img src={icon_l} alt='' />
            <div className='dashed' />
            <img src={icon_r} alt='' />
          </Col>
        </Row>
        <Row>
          <Col className='cen'>请打开鲸矿APP扫码</Col>
        </Row>
        <Row>
          <Col className='btm' span={10} offset={7}>
            <div className='box'>
              {forbiddenScanStatus === 1 ? (
                <div className='mask'>
                  <span className='pending'>等待中...</span>
                </div>
              ) : null}
              <div className='qrcode-box'>
                <QRCode value={qrInfo} size={180} renderAs={'svg'} />
              </div>
            </div>
          </Col>
        </Row>
      </Modal>
    )
  }

  render() {
    return (
      <div className='detail'>
        <Card className='contain'>
          <h1>资产详情</h1>
          <div className='info'>{this.renderInfo()}</div>
          <div className='tab'>
            <Tabs defaultActiveKey='1' onChange={this.changeTab}>
              <TabPane tab='资产持有情况' key='1'>
                {this.renderTab1()}
              </TabPane>
              <TabPane tab='操作日志' key='2'>
                {this.renderTab2()}
              </TabPane>
            </Tabs>
            {this.state.tabKey === 1 && (
              <div className='search-box'>
                <Input
                  placeholder='请输入您要查询的地址'
                  value={this.state.param_search}
                  onChange={this.handlerInputAddress}
                />
                <Button onClick={this.doSearchHolding}>搜索</Button>
              </div>
            )}
          </div>
        </Card>
        {this.renderModal()}
      </div>
    )
  }
}

export default Detail
