import React, { Component } from 'react'
import './document.less'

class ServiceAgreement extends Component {
  render() {
    return (
      <div className='document-wrp'>
        <h3>隐私条款</h3>
        <p>一、双方</p>
        <p>1.1 域乎资产交易平台（以下简称“平台”）是一个专门供用户进行数字资产交易和提供相关服务（以下称“该服务”或“服务”）的平台。为了本协议表述之方便，平台在本协议中使用“我们”或其他第一人称称呼。</p>
        <p>1.2 只要登陆平台的自然人或其他主体均为本平台的用户，本协议表述之便利，以下使用“您”或其他第二人称。</p>
        <p>1.3 为了本协议表述之便利，我们和您在本协议中合称为“双方”，我们或您单称为“一方”。</p>
        <p></p>
        <p>二、隐私政策制定的目的</p>
        <p>隐私政策规定了我们只通过您登陆本平台、在本平台上注册和/或使用我们提供的服务，采集您信息的类型以及我们如何使用和保护这些采集到的信息。</p>
        <p></p>
        <p>三、您的同意</p>
        <p>为确保您对我们在处理个人资料上有充分信心， 您切要详细阅读及理解隐私政策的条文。特别是您一旦登陆我们的平台，不论您是否在该平台上注册，您均向我们表明您接受、同意、承诺和确认：</p>
        <p>3.1 您在自愿下同意向我们披露个人资料。</p>
        <p>3.2 您会遵守本隐私政策的全部条款和限制。</p>
        <p>3.3 您同意我们通过您登陆本平台、在本平台上注册和/或使用我们提供的服务收集您的信息；您同意日后我们对隐私政策的任何修改。</p>
        <p>3.4 您同意我们的分公司、附属公司、雇员就您可能会感兴趣的产品和服务与您联络（除非您已经表示不想收到该等信息）。</p>
        <p></p>
        <p>四、收集的信息</p>
        <p>4.1 当您使用本平台时，您同意我们开始使用cookies来追踪您的每一个动作，并收集和记住您留下的所有信息，其中包括但不限于您的IP地址，地理位置及其他资料。</p>
        <p>4.2 如果您愿意使用本平台提供的服务，您需要填写和提供以下两种信息：</p>
        <p>4.2.1 身份信息。该信息可以帮助我们验证您是否有资格注册为本平台会员，包括但不限于您的名字、您的居住地址、邮寄地址、您所属国家或政府签发的其他具有证明您身份信息的不同的证书以及涉及到的号码及所有其他可帮助我们验证您身份的信息（以下合称为“身份信息” ）。</p>
        <p>4.2.2 服务信息。该信息帮助我们与您联系并顺利为您提供服务，包括但不限于您的电话号码、传真号码、有效的电子邮件地址、邮寄地址以及您的借记卡信息和/或其他帐户信息（以下合称为“服务信息”）。</p>
        <p>4.3 在您使用本平台或其提供的服务时，我们可能会为了改进本平台的使用功能，提升您对使用本平台及其提供的服务及其安全性的体验或是根据法院或可适用法律法规或有管辖权的其他政府机构的命令，通过本平台公示的专属于我们的邮箱或其他我们认为合规的方式收集更多的必要的信息。</p>
        <p>4.4 如果您访问了在本平台上的其他第三方平台或合作的第三方的任何链接，您应同意并遵守该第三方单独和独立的隐私政策。我们对这些平台或合作方的内容及活动不承担任何责任。</p>
        <p></p>
        <p>五、Cookies</p>
        <p>5.1 当您到访我们平台时， 我们通过cookies去记录我们的业绩以及核对在线广告的效果。Cookie是发送到您的浏览器上并在您的电脑硬盘驱动器上存储的少量数据。只有当您使用您的电脑进入我们平台时，Cookie才能够被发送到您的电脑硬盘上。</p>
        <p>5.2 Cookies常用于记录访问者浏览我们平台上的各个项目时的习惯以及偏好。Cookies所搜集的资料是不记名的集体统计数据，不载有个人资料。</p>
        <p>5.3 Cookies不能用于取得您的硬盘上的数据、您的电邮地址、及您的私人数据，其可使本平台或服务商系统识别到您的浏览器并捕捉和记忆信息。大多数浏览器都预设为可以接受Cookies。您可以选择将您的浏览器设定为不接受Cookies，或如果Cookies一被装上就通知您。不过，若设定为禁止Cookies，您或许便不能启动或使用我们平台的某些功能。</p>
        <p></p>
        <p>六、信息使用用途</p>
        <p>6.1 我们为了以下目的或以下列方式使用我们收集到的您的信息：</p>
        <p>6.1.1 通过我们的平台向您提供我们的各项服务。</p>
        <p>6.1.2 当您使用我们的平台时，能辨认及确认您的身份。</p>
        <p>6.1.3 为了改善和提高平台的服务（根据我们收到的您的信息及反馈，帮助我们改善平台的服务，进而我们可以更有效地对您的服务请求和支持需求做出回应）。</p>
        <p>6.1.4 统计我们平台使用量的数据和政府机关、事业单位等合作的数据分析。</p>
        <p>6.1.5 个性化您的体验（您的信息将帮助我们更好地为您的个性化需求作出回应）。</p>
        <p>6.1.6 处理交易（您的信息，无论是公共或私人的，未经您的同意不会以任何理由被出售、交换、转移，或提供给任何其他公司，但除了为完成您所要求的交易的明确目的之外）。</p>
        <p>6.1.7 定期发送电子邮件（您提供订单处理的电子邮件地址，除了偶尔接收我们的新闻，更新，相关产品或服务的信息等，可用来向您发送信息和更新有关您的订单）。</p>
        <p>6.1.8 满足本平台用户协议规定的其他目的和为了满足该等目的的所有合法途径。</p>
        <p>6.2 我们不会向其他方出售、交易或以其他方式转让信息或允许他人收集、使用信息，但不包括以下其他方和以下信息：我们的关联方、帮助我们经营我们的平台、开展业务、或者向您提供服务的受信任的第三方，只要这些当事方同意将这些信息保密；当我们相信披露的信息是适当的，是遵守法律、法规、规章制度的或来自于法院或他主管当局的命令、执行我们的平台策略，正常运行平台所需要，关联方提供服务所需或保护我们或他人的权利、财产或安全的。但是，您的信息不会提供给其他方进行营销，广告或其他用途。</p>
        <p></p>
        <p>七、个人资料的保护</p>
        <p>7.1 我们实施妥善的实物、电子、管理及技术方面的措施来保护和保障您的个人资料的安全。我们尽力确保通过我们平台所搜集的任何个人资料皆免于任何与我们无关的第三者的滋扰。我们采取的安全措施包括但不限于：</p>
        <p>7.1.1 实物措施：存有您个人资料的记录会被存放在有锁的地方。</p>
        <p>7.1.2 电子措施：存有您个人资料的电脑数据会被存放在受严格登入限制的电脑系统和存储媒体上。</p>
        <p>7.1.3 管理措施：只有经我们授权的职员才能接触到您的个人资料，这些职员需要遵守我们个人资料保密的内部守则。</p>
        <p>7.1.4 技术措施：可能采用如Secure Socket Layer Encryption这种加密技术来输送您的个人资料。</p>
        <p>7.1.5 其它措施：我们的网络服务器受到“防火墙”的保护。</p>
        <p>7.2 若您知悉我们的平台上有任何安全方面的漏洞，请马上联系我们，使我们可以尽快采取妥适的行动。</p>
        <p>7.3 尽管实施了上述技术和保安的措施，我们不能保证资料在互联网上的输送绝对安全，因此我们不能绝对保证您通过我们平台提供给我们的个人资料在一切时候都是安全的。对任何因未经授权而接触您个人资料所发生的事件我们一概不承担责任，于这方面产生或导致的任何损失或损害，我们不负责赔偿。</p>
        <p></p>
        <p>八、隐私政策的修订</p>
        <p>我们保留随时修改该隐私政策的权利。我们通过更新和发布新版本的生效日期告诉您我们修订了隐私政策，并突出修订处，有时我们会发布通知告诉您隐私政策已修订，但这不是我们的一项义务。您应定期查看隐私政策并关注其修订情况，如果您不同意修订的内容，您应停止立即访问本平台。当隐私政策的更新版本发布，您持续访问本平台显示和表明您同意该更新的内容，并同意遵守该更新的隐私权政策。</p>
        <p></p>
        <p>九、与我们的沟通</p>
        <p>9.1 如果您有任何要求和意见，您可以通过电子邮件cs@yuhu.tech，这是属于我们与您沟通的唯一有效和官方电子邮件，所以我们对您未使用有效的联系方式，任何作为或不作为不承担责任。</p>
        <p>9.2 我们只通过本平台上的有效的联系方式发布公告和信息或在本平台张贴公告，所以我们对由于您信任了未通过以上方式获得的信息而产生的任何损失不承担责任。</p>
        <p>9.3 若您对我们的隐私政策有任何问题，欢迎随时联系我们。</p>
        <p></p>
        <p>用户协议</p>
        <p>域乎资产交易平台（以下简称“平台”）是一个专门供用户进行数字资产交易和提供相关服务（以下称“该服务”或“服务”）的平台。为了本协议表述之方便，平台在本协议中使用“我们”或其他第一人称称呼。只要登陆平台的自然人或其他主体均为本平台的用户，本协议表述之便利，以下使用“您”或其他第二人称。为了本协议表述之便利，我们和您在本协议中合称为“双方”，我们或您单称为“一方”。本平台所有内容，为便利用户，可能提供多个语言版本，若有冲突或遗漏等情况，以中文内容为准。</p>
        <p>敬告您：</p>
        <p>1 您了解本平台仅作为您获取数字资产信息、寻找交易方、就数字资产的交易进行协商及开展交易的场所，本平台不参与您的任何交易，故您应自行谨慎判断确定相关数字资产及/或信息的真实性、合法性和有效性，并自行承担因此产生的责任与损失。</p>
        <p>2 本平台的任何意见、消息、探讨、分析、价格、建议和其他资讯均是一般的市场评论，并不构成投资建议。我们不承担任何因依赖该资讯直接或间接而产生的损失，包括但不限于任何利润损失。</p>
        <p>3 本平台的内容会随时更改并不作另行通知，我们已采取合理措施确保平台资讯的准确性，但并不能保证其准确性程度，亦不会承担任何因本平台上的资讯或因未能链结互联网、传送或接收任何通知和信息时的延误或失败而直接或间接产生的损失。</p>
        <p>4 使用互联网形式的交易系统亦存有风险，包括但不限于软件，硬件和互联网链结的失败等。由于我们不能控制互联网的可靠性和可用性，我们不会对失真，延误和链结失败而承担任何责任。</p>
        <p>5 www.yuhu.tech为本平台唯一官方对外信息公布平台。</p>
        <p>6 本平台任何服务均不接受信用卡支付。</p>
        <p>7 禁止使用本平台从事洗钱、走私、商业贿赂等一切非法交易活动或违法行为，若发现任何涉嫌非法交易或违法行为，本站将采取各种可使用之手段，包括但不限于冻结账户，通知相关权力机关等，我们不承担由此产生的所有责任并保留向相关人士追究责任的权利。</p>
        <p>8 禁止使用本平台进行恶意操纵市场、不正当交易等一切不道德交易活动，若发现此类事件，本平台将对所有恶意操纵价格、恶意影响交易系统等不道德的行为采取警告、限制交易、关停账户等预防性地保护措施，我们不承担由此产生的所有责任并保留向相关人士追究责任的权利。</p>
        <p></p>
      </div>
    )
  }
}

export default ServiceAgreement
