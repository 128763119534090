import { observable, action } from 'mobx'
import { mutation, subscription } from '../../common/apollo'
import { message } from 'antd'
import { config } from '../../config'

export default class TxsStore {
  /* obserable */
  @observable
  isModalShow = false

  @observable
  scanStatus = 999

  @observable
  txid = ''

  currentSubscription = null

  /* action */
  @action
  showModal = () => {
    this.isModalShow = true
  }

  @action
  hideModal = () => {
    this.isModalShow = false
    this.scanStatus = 999
    this.currentSubscription.unsubscribe()
  }

  @action
  getTxid = async () => {
    let that = this
    const variables = null
    const body = `
      mutation getTxid{
        startTrading
      }`
    let res = await mutation(body, variables)
    if (res) {
      this.txid = res.startTrading
      let wsVariables = {
        txId: res.startTrading
      }
      let wsBody = `
        subscription checkTrading(
          $txId: String!
        ) {
          checkTrading(
            txId: $txId
          )
        }
      `
      subscription(wsBody, wsVariables).then(res => {
        let test = res.subscribe({
          next(data) {
            let checkTrading = data.data.checkTrading
            if (checkTrading === 2) {
              that.hideModal()
              message.success('转账成功')
              setTimeout(window.location.replace(`${config.host_route}/#/assets/home`), 1000)
            }
            that.scanStatus = checkTrading
          }
        })
        this.currentSubscription = test
      })
    }
  }
}
