import React, { Component, Fragment } from 'react'
import { observer, inject } from 'mobx-react'
import { Card, Row, Col, Input, Button, Divider, Table, Select, Modal, message } from 'antd'
import QRCode from 'qrcode.react'

import './index.less'
import { QR, config, OriginToken } from '../../../config'

import icon_l from '../../../images/pop-0.png'
import icon_r from '../../../images/pop-1.png'

const { Option } = Select
const col_csv = [
  {
    title: '编号',
    dataIndex: 'id',
    width: '30%'
  },
  {
    title: '错误行数',
    dataIndex: 'num',
    width: '40%'
  },
  {
    title: '错误原因',
    dataIndex: 'message',
    width: '30%'
  }
]

@inject('dropStore')
@observer
class DropNew extends Component {
  constructor(props) {
    super(props)
    this.state = {
      flag_csv: true,
      flag_name: true,
      flag_address: true,
      airdropId: '',
      param_name: '',
      param_symbol: '',
      param_address: '',
      param_csvLink: ''
    }
  }

  async componentDidMount() {
    const { dropStore } = this.props
    const { getAirdropInfo, getFuzzyAssets, resetCsvInfoFlag } = dropStore
    const defaultKeyword = ''
    const airdropId = this.props.match.params.id

    await resetCsvInfoFlag()
    await getFuzzyAssets(defaultKeyword)
    let editAirdropInfo = await getAirdropInfo(airdropId)
    this.setState({
      airdropId: airdropId,
      param_name: editAirdropInfo.airdropName,
      param_symbol: editAirdropInfo.assetSymbol,
      param_address: editAirdropInfo.assetAddress,
      param_csvLink: editAirdropInfo.csvFilePath
    })
    this.checkIsCsvDisable()
  }

  checkIsCsvDisable = () => {
    const { param_name, param_address } = this.state
    if (param_name) {
      this.setState({
        flag_name: false
      })
    }
    if (param_address) {
      this.setState({
        flag_address: false
      })
    }
    if (param_name && param_address) {
      this.setState({
        flag_csv: false
      })
    }
  }

  setParamName = e => {
    let temp = false
    let value = e.target.value
    let regName = /^[\u4e00-\u9fa5a-zA-Z]+$/
    if (!regName.test(value)) {
      temp = true
    }
    this.setState({
      param_name: value,
      flag_name: temp
    })
    if (!this.state.flag_address && !temp) {
      this.setState({
        flag_csv: false
      })
    } else {
      this.setState({
        flag_csv: true
      })
    }
  }

  handleChange = (contractAddress, option) => {
    this.setState({
      param_symbol: option.key,
      param_address: contractAddress,
      flag_address: false
    })
    if (!this.state.flag_name) {
      this.setState({
        flag_csv: false
      })
    } else
      this.setState({
        flag_csv: true
      })
  }

  handleSearch = value => {
    const { getFuzzyAssets } = this.props.dropStore
    getFuzzyAssets(value)
  }

  renderForm = () => {
    const { dropAssetsList } = this.props.dropStore
    return (
      <Fragment>
        <Row className='row-ipt'>
          <Col span={3} offset={3}>
            <div className='label'>空投名称</div>
          </Col>
          <Col span={15}>
            <div className='input-box'>
              <Input
                placeholder='请输入空投名称'
                onChange={e => this.setParamName(e)}
                value={this.state.param_name}
              />
            </div>
            <div className='tip'>{this.state.flag_name && '中文字符、英文字母。'}</div>
          </Col>
        </Row>
        <Row>
          <Col span={3} offset={3}>
            <div className='label'>空投资产</div>
          </Col>
          <Col span={15}>
            <div className='input-box'>
              <Select
                showSearch
                value={this.state.param_address}
                placeholder={'请选择或输入'}
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                onSearch={this.handleSearch}
                onChange={this.handleChange}
                notFoundContent={null}
              >
                {dropAssetsList.map(item => (
                  <Option key={item.assetSymbol} value={item.contractAddress}>{`${
                    item.assetSymbol
                  } ${item.contractAddress}`}</Option>
                ))}
              </Select>
            </div>
            <div className='tip'>{this.state.flag_address && '请选择空投资产'}</div>
          </Col>
        </Row>
        <Row className='row-csv'>
          <Col span={3} offset={3}>
            <div className='label'>导入数据</div>
          </Col>
          <Col span={15}>
            <div className='csv'>
              每次最多只能导入1万条数据，超出将导入失败
              {this.state.flag_csv && <span>请先完善空投名称与空投资产</span>}
            </div>
            <div className='csv-btn'>
              <Button>
                <a href={config.csvTemplateUrl}>下载CSV模板</a>
              </Button>
              <input
                disabled={this.state.flag_csv}
                type='file'
                accept='.csv'
                required
                onChange={this.uplaodCsv}
                ref={file => {
                  this.uploadInput = file
                }}
              />
              <Button disabled={this.state.flag_csv}>导入CSV</Button>
            </div>
          </Col>
        </Row>
      </Fragment>
    )
  }

  uplaodCsv = async e => {
    const { airdropId } = this.state
    const { uploadCsv } = this.props.dropStore
    let file = e.target.files[0]
    await uploadCsv(airdropId, file)
    this.uploadInput = ''
  }

  renderTable = () => {
    const { dropStore } = this.props
    const { csvErrorList } = dropStore
    return (
      <div className='table'>
        <Table
          rowKey={item => item.num}
          columns={col_csv}
          dataSource={csvErrorList}
          pagination={false}
        />
      </div>
    )
  }

  renderResult = () => {
    const { dropStore } = this.props
    const { param_symbol } = this.state
    const { csvInfo, csvInfoFlag } = dropStore
    return (
      <Fragment>
        {csvInfoFlag === 2 && (
          <div className='result'>
            <p>
              本次共导入{` ${csvInfo.addressAmount || '-'} `}条地址信息，已检测出
              <span className='err'>{` ${csvInfo.errorAmount || '-'} `}条</span>
              错误地址，超过20条后无法检测。
            </p>
            <p>请修正后重新上传。</p>
          </div>
        )}
        {csvInfoFlag === 1 && (
          <div className='result'>
            <p>
              本次共导入{` ${csvInfo.addressAmount || '-'} `}条地址信息，共
              {` ${csvInfo.addressAmount || '-'} `}
              个地址账户。
            </p>
            <p>
              空投将消耗{` ${csvInfo.tokenAmount || '-'} `}
              {param_symbol}，所需手续费为{` ${csvInfo.airdropFee || '-'} `}
              {OriginToken}。
            </p>
            <div className='btn-box'>
              <Button size='large' className='n' onClick={this.doSave}>
                保存
              </Button>
              <Button size='large' className='b' onClick={this.doShowModal}>
                开始空投
              </Button>
            </div>
          </div>
        )}
      </Fragment>
    )
  }

  doSave = async () => {
    const { airdropId, param_name, param_symbol, param_address } = this.state
    const { saveAirdrop } = this.props.dropStore
    let isSaveSuccess = await saveAirdrop(airdropId, param_name, param_symbol, param_address)
    if (isSaveSuccess) {
      message.success('保存成功')
    } else {
      message.error('保存失败')
    }
  }

  doShowModal = async () => {
    const { doStartAirdrop, showAirdropModal } = this.props.dropStore
    await this.doSave()
    doStartAirdrop()
    showAirdropModal()
  }

  renderModal = () => {
    const {
      airdropModalStatus,
      hideAirdropModal,
      airdropScanStatus,
      airdropTxId
    } = this.props.dropStore
    const { airdropId } = this.state
    let qrInfo = QR.airdrop(airdropTxId, airdropId)
    return (
      <Modal
        title='空投签名授权'
        visible={airdropModalStatus}
        onOk={hideAirdropModal}
        onCancel={hideAirdropModal}
        footer={null}
        closable={false}
      >
        <Row>
          <Col className='top' span={10} offset={7}>
            <img src={icon_l} alt='' />
            <div className='dashed' />
            <img src={icon_r} alt='' />
          </Col>
        </Row>
        <Row>
          <Col className='cen'>请打开鲸矿APP扫码</Col>
        </Row>
        <Row>
          <Col className='btm' span={10} offset={7}>
            <div className='box'>
              {airdropScanStatus === 1 ? (
                <div className='mask'>
                  <span className='pending'>等待中...</span>
                </div>
              ) : null}
              <div className='qrcode-box'>
                <QRCode value={qrInfo} size={180} renderAs={'svg'} />
              </div>
            </div>
          </Col>
        </Row>
      </Modal>
    )
  }

  render() {
    const { csvInfoFlag, editAirdropState } = this.props.dropStore
    return (
      <div className='dropnew'>
        <Card className='contain'>
          <h1>新建空投</h1>
          {this.renderForm()}
          {csvInfoFlag === 2 && <Divider className='divider-form' />}
          {csvInfoFlag === 2 && this.renderTable()}
          {(csvInfoFlag !== 0 || editAirdropState === 2) && <Divider />}
          {this.renderResult()}
        </Card>
        {this.renderModal()}
      </div>
    )
  }
}

export default DropNew
