import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import { Row, Col, Card, Steps, Button, Input, message, Spin, Icon, Select } from 'antd'
import { checkEmail, getImgBase64, checkPhoneNum } from '../../../common/tool'
import './index.less'

import id_enterprise from '../../../images/id-enterprise.png'
import pass from '../../../images/icon-pass.png'
import pending from '../../../images/icon-examine.png'
import id_front from '../../../images/id-front.png'
import id_back from '../../../images/id-back.png'

const Step = Steps.Step
const Option = Select.Option
const antIcon = <Icon type='loading' style={{ fontSize: 24 }} spin />

@inject('verifyStore', 'userStore')
@observer
class Enterprise extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ID_front_img: id_front,
      ID_back_img: id_back,
      ID_company_img: id_enterprise,
      index: 0
    }
  }

  async componentDidMount() {
    const { verifyStore } = this.props
    const { reset, getVerificationStatus } = verifyStore
    reset()
    getVerificationStatus()
  }

  renderSteps = () => {
    const { verifyStore } = this.props
    const { current_enterprise } = verifyStore
    return (
      <Row>
        <Col span={22} offset={1}>
          <Steps labelPlacement='vertical' current={current_enterprise}>
            <Step title='选择认证信息' />
            <Step title='提交认证信息' />
            <Step title='审核中' />
            <Step title='认证结果' />
          </Steps>
        </Col>
      </Row>
    )
  }

  renderLicense = () => {
    const { verifyStore } = this.props
    const { upCompanyLoading, company_request_info } = verifyStore
    return (
      <Row className='row-end'>
        <Col span={3} offset={2}>
          <div className='label'>营业执照</div>
        </Col>
        <Col span={14} offset={2}>
          <div className='id-box'>
            <span>1.请提供证件的原件照片，文字、头像清晰可辨认</span>
            <span>2.格式要求jpg/png，大小不超过3M</span>
            <div>
              <input
                type={'file'}
                accept={'image/*'}
                ref={'file'}
                onChange={this.changeCompanyClick}
                style={{
                  position: 'absolute',
                  top: '70px',
                  width: '175px',
                  height: '120px',
                  opacity: 0,
                  cursor: 'pointer',
                  zIndex: 999
                }}
              />
              <Spin tip='上传中...' spinning={upCompanyLoading} indicator={antIcon} size={'large'}>
                {company_request_info.id
                  ? this._idHasIdDom(this.state.ID_company_img)
                  : this._idDefaultDom(id_enterprise, '营业执照')}
              </Spin>
            </div>
          </div>
        </Col>
      </Row>
    )
  }

  _idDefaultDom = (img, info) => (
    <div className='card' style={{ width: '175px', height: '120px', padding: 0 }}>
      <img src={img} alt='id-front' />
      <span style={{ fontSize: '12px' }}>+ 点击上传{info}</span>
    </div>
  )

  _idHasIdDom = img => (
    <div className='card' style={{ padding: 0 }}>
      <img src={img} alt='id-front' style={{ width: '175px', height: '120px' }} />
    </div>
  )

  changeCompanyClick = async event => {
    const { verifyStore } = this.props
    const { getIdComapnyInfo } = verifyStore
    let base_64 = await getImgBase64(event.target.files[0])
    this.setState({
      ID_company_img: base_64
    })
    getIdComapnyInfo(base_64)
  }

  changeFrontClick = async event => {
    const { verifyStore } = this.props
    const { getIdCardInfo } = verifyStore
    let base_64 = await getImgBase64(event.target.files[0])
    this.setState({
      ID_front_img: base_64
    })
    getIdCardInfo(base_64, 'front')
  }

  changeBackClick = async event => {
    const { verifyStore } = this.props
    const { getIdCardInfo } = verifyStore
    let base_64 = await getImgBase64(event.target.files[0])
    this.setState({
      ID_back_img: base_64
    })
    getIdCardInfo(base_64, 'back')
  }

  renderId = () => {
    const { verifyStore } = this.props
    const {
      id_front_img,
      id_back_img,
      id_back_info,
      id_front_info,
      isAccordCom,
      isInvalid,
      upFrontLoading,
      upBackLoading
    } = verifyStore
    return (
      <Row className='row-end'>
        <Col span={3} offset={2}>
          <div className='label'>身份证照片</div>
        </Col>
        <Col span={14} offset={2}>
          <div className='id-box'>
            <span>1.请提供证件的原件照片，文字、头像清晰可辨认</span>
            <span>2.格式要求jpg/png，大小不超过3M</span>
            <div>
              <input
                type={'file'}
                accept={'image/*'}
                ref={'file'}
                onChange={this.changeFrontClick}
                style={{
                  position: 'absolute',
                  top: '70px',
                  width: '175px',
                  height: '120px',
                  opacity: 0,
                  cursor: 'pointer',
                  zIndex: 999
                }}
              />
              <Spin tip='上传中...' spinning={upFrontLoading} indicator={antIcon} size={'large'}>
                {id_front_info.residentialAddress
                  ? this._idHasIdDom(this.state.ID_front_img)
                  : this._idDefaultDom(id_front_img, '身份证正面')}
              </Spin>
            </div>
            {isAccordCom ? <span className='tip'>您所填的身份证信息与上传照片不符</span> : null}
            <div>
              <input
                type={'file'}
                accept={'image/*'}
                ref={'file'}
                onChange={this.changeBackClick}
                style={{
                  position: 'absolute',
                  bottom: 0,
                  width: '175px',
                  height: '120px',
                  opacity: 0,
                  cursor: 'pointer',
                  zIndex: 999
                }}
              />
              <Spin tip='上传中...' spinning={upBackLoading} indicator={antIcon} size={'large'}>
                {id_back_info.issueDate
                  ? this._idHasIdDom(this.state.ID_back_img)
                  : this._idDefaultDom(id_back_img, '身份证背面')}
              </Spin>
            </div>
            {isInvalid ? (
              <span className='tip'>
                请确认身份证有效期为：{id_back_info.issueData}-{id_back_info.invalidData}
                身份证有效期已过
              </span>
            ) : null}
          </div>
        </Col>
      </Row>
    )
  }

  renderContent = () => {
    const { verifyStore } = this.props
    const {
      nextEnterprise,
      getCompanyInfo,
      company_input_info,
      company_request_info,
      id_front_info
    } = verifyStore
    return (
      <div className='content'>
        <Row>
          <Col span={3} offset={2}>
            <div className='label'>企业名称</div>
          </Col>
          <Col span={14} offset={2}>
            <div className='input-box'>
              <Input
                placeholder='请输入营业执照上的企业名称'
                onChange={e => {
                  getCompanyInfo('company_input_name', e.target.value)
                }}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={3} offset={2}>
            <div className='label'>联系人</div>
          </Col>
          <Col span={14} offset={2}>
            <div className='input-box'>
              <Input
                placeholder='请输入联系人姓名'
                onChange={e => {
                  getCompanyInfo('company_input_username', e.target.value)
                }}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={3} offset={2}>
            <div className='label'>联系人电话</div>
          </Col>
          <Col span={14} offset={2}>
            <div className='input-box'>
              <Input
                placeholder='请输入联系人电话'
                onChange={e => {
                  getCompanyInfo('company_input_contacter_phone', e.target.value)
                }}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={3} offset={2}>
            <div className='label'>电子邮箱</div>
          </Col>
          <Col span={14} offset={2}>
            <div className='input-box'>
              <Input
                placeholder='请输入邮箱地址'
                onChange={e => {
                  getCompanyInfo('company_input_email', e.target.value)
                }}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={3} offset={2}>
            <div className='label'>营业执照注册号</div>
          </Col>
          <Col span={14} offset={2}>
            <div className='input-box'>
              <Input
                placeholder='请输入营业执照上的注册号或统一社会信用代码号'
                onChange={e => {
                  getCompanyInfo('company_input_idnum', e.target.value)
                }}
              />
            </div>
          </Col>
        </Row>
        {this.renderLicense()}
        <Row>
          <Col span={3} offset={2}>
            <div className='label'>附加认证</div>
          </Col>
          <Col span={14} offset={2}>
            <div className='input-box'>
              <Input
                placeholder='企业对公账户认证'
                onChange={e => {
                  getCompanyInfo('company_input_addinfo', e.target.value)
                }}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={3} offset={2}>
            <div className='label'>银行开户名</div>
          </Col>
          <Col span={14} offset={2}>
            <div className='input-box'>
              <Input
                placeholder='请输入银行开户人姓名'
                onChange={e => {
                  getCompanyInfo('company_input_userbankname', e.target.value)
                }}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={3} offset={2}>
            <div className='label'>对公账户开户行</div>
          </Col>
          <Col span={14} offset={2}>
            <div className='input-box'>
              <Input
                placeholder='请输入对公账户开户行网点'
                onChange={e => {
                  getCompanyInfo('company_input_bank', e.target.value)
                }}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={3} offset={2}>
            <div className='label'>对公账户账号</div>
          </Col>
          <Col span={14} offset={2}>
            <div className='input-box'>
              <Input
                placeholder='请输入对公账户账号'
                onChange={e => {
                  getCompanyInfo('company_input_banknum', e.target.value)
                }}
              />
            </div>
          </Col>
        </Row>
        {this.renderId()}
        <div className='btn-box'>
          <Button
            size='large'
            onClick={() => {
              if (!company_input_info['company_input_name']) {
                message.error('请输入营业执照上的企业名称，名称中含括号()为英文输入法括号')
                return
              }
              if (!company_input_info['company_input_username']) {
                message.error('请输入联系人姓名')
                return
              }
              if (!company_input_info['company_input_contacter_phone']) {
                message.error('请输入联系人电话')
                return
              }
              if (!checkPhoneNum(company_input_info['company_input_contacter_phone'])) {
                message.error('电话格式不合法，请检查重新输入')
                return
              }
              if (!company_input_info['company_input_email']) {
                message.error('请输入邮箱地址')
                return
              }
              if (!checkEmail(company_input_info['company_input_email'])) {
                message.error('邮箱格式不合法，请检查重新输入')
                return
              }
              if (!company_input_info['company_input_idnum']) {
                message.error('请输入营业执照上的注册号码')
                return
              }
              if (!company_input_info['company_input_addinfo']) {
                message.error('请输入企业对公账户认证')
                return
              }
              if (!company_input_info['company_input_userbankname']) {
                message.error('请输入银行开户人姓名')
                return
              }
              if (!company_input_info['company_input_bank']) {
                message.error('请输入对公账户开户行网点')
                return
              }
              if (!company_input_info['company_input_banknum']) {
                message.error('请输入对公账户账号')
                return
              }
              if (
                company_input_info['company_input_name'] !== company_request_info.enterpriseName
              ) {
                message.error('公司名与营业执照不一致，名称中含括号()为英文输入法括号')
                return
              }
              if (company_input_info['company_input_username'] !== id_front_info.name) {
                message.error('联系人与身份证姓名不一致')
                return
              }
              if (
                company_input_info['company_input_idnum'] !==
                company_request_info.unifiedSocialCreditCode
              ) {
                message.error('注册号与营业执照不一致')
                return
              }
              nextEnterprise()
            }}
          >
            提交
          </Button>
        </div>
      </div>
    )
  }

  renderWaiting = () => {
    return (
      <div className='pass'>
        <img src={pending} alt='pass' />
        <p>我们正在加紧审核您提交的资料，需要1～3个工作日，请耐心等待。</p>
      </div>
    )
  }

  renderPass = () => {
    const { verifyStore } = this.props
    const { company_verification_info } = verifyStore
    return (
      <div className='pass'>
        <img src={company_verification_info.status === 1 ? pass : pending} alt='pass' />
        {company_verification_info.status === 1 ? this.receiveAwards() : null}
        <p>企业名称：{company_verification_info.companyName}</p>
        <p>联系人：{company_verification_info.contacter}</p>
        <p>手机号：{company_verification_info.phoneNumber}</p>
        <p>电子邮箱：{company_verification_info.email}</p>
        <p>营业执照注册号：{company_verification_info.registrationNo}</p>
        <p>营业执照</p>
        <div className='box'>
          <div className='card'>
            <img
              src={company_verification_info.bussinessLicenseImgURL}
              alt='id-front'
              style={{ width: '210px' }}
            />
          </div>
        </div>
        <p>附加认证：{company_verification_info.additionalAuthentication}</p>
        <p>银行开户名：{company_verification_info.accountName}</p>
        <p>对公账户开户行：{company_verification_info.corporateAccountOfDepositBank}</p>
        <p>对公账户账号：{company_verification_info.corporateAccountNo}</p>
        <p>联系人身份证照片</p>
        <div className='box'>
          <div className='card' style={{ padding: 0 }}>
            <img
              src={company_verification_info.idCardFrontImgURL}
              alt='id-front'
              style={{ width: '210px', height: '125px' }}
            />
          </div>
          <div className='card' style={{ padding: 0 }}>
            <img
              src={company_verification_info.idCardBackImgURL}
              alt='id-back'
              style={{ width: '210px', height: '125px' }}
            />
          </div>
        </div>
      </div>
    )
  }

  selectOption = e => {
    this.setState({
      index: e
    })
  }

  receiveAwards = () => {
    const { index } = this.state
    const { userStore } = this.props
    const { cardList, receiveAwards, userInfo } = userStore
    return (
      <div className='receive-awards-wrp'>
        <div className='left-title'>选择领取地址</div>
        <div className='right-con'>
          <div className='name'>{cardList.length ? cardList[index].nickname : ''}</div>
          <div className='address'>{cardList[index].addressString}</div>
          <Select
            style={{ width: 90 }}
            placeholder={cardList.length > 0 ? cardList[index].nickname : ''}
            suffixIcon={<Icon type='more' />}
            onChange={this.selectOption}
          >
            {cardList.map((item, index) => {
              return (
                <Option value={index} key={item.id}>
                  {item.nickname ? item.nickname : ''}
                </Option>
              )
            })}
          </Select>
        </div>
        <Button
          className='awards-number'
          type='primary'
          disabled={userInfo.awardsNumber === 0 ? true : false}
          onClick={() => {
            receiveAwards(cardList[index].addressString)
          }}
        >
          领取奖励：剩余{userInfo.awardsNumber}次
        </Button>
      </div>
    )
  }

  render() {
    const { verifyStore } = this.props
    const { current_enterprise } = verifyStore
    return (
      <div className='verify'>
        <Card>
          <h1>企业用户实名认证</h1>
          {this.renderSteps()}
          {current_enterprise === 1 && this.renderContent()}
          {current_enterprise === 2 && this.renderWaiting()}
          {current_enterprise === 3 && this.renderPass()}
        </Card>
      </div>
    )
  }
}

export default Enterprise
