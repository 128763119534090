import React, { Component, Fragment } from 'react'
import { observer, inject } from 'mobx-react'
import { HashRouter as Router, Route, Switch, NavLink } from 'react-router-dom'
import { Layout, Row, Col, Divider, Button } from 'antd'
import './index.less'
import { config, copyright } from '../config'

import { Login, Tip } from '../pages/User'
import Download from '../pages/Download'
import Process from '../pages/Process'
import PageRouter from '../router'
import AboutUs from '../pages/Document/AboutUs'
import ContactUs from '../pages/Document/ContactUs'
import ServiceAgreement from '../pages/Document/ServiceAgreement'

import logo from '../images/logo.png'

const { Header, Footer, Content } = Layout

@inject('userStore')
@observer
class BaseRouter extends Component {
  async componentDidMount() {
    const { history, userStore } = this.props
    const { setLoginStatus, logout, getUserInfo } = userStore
    setLoginStatus()
    if (!localStorage.token) {
      logout()
      history.push('/login')
    } else {
      await getUserInfo()
    }
  }

  logout = () => {
    const { history, userStore } = this.props
    userStore.logout()
    history.push('/login')
  }

  render() {
    const { isLogin, userInfo } = this.props.userStore
    return (
      <div className='layout'>
        <Layout>
          <Header>
            <div className='header-box'>
              <div
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  this.props.history.push('/assets/home')
                }}
              >
                <img className='logo' src={logo} alt='' />
              </div>
              <div>
                <Router>
                  <NavLink className='download' to='/download' activeClassName='nav-active'>
                    APP下载
                  </NavLink>
                </Router>
                <Router>
                  <NavLink
                    className='assets'
                    to={isLogin === 1 ? '/assets' : '/login'}
                    activeClassName='nav-active'
                  >
                    资产
                  </NavLink>
                </Router>
                <span
                  className='explorer'
                  onClick={() => window.open(config.explorer)}
                  style={{ cursor: 'pointer' }}
                >
                  浏览器
                </span>
                {isLogin === 1 && (
                  <Fragment>
                    <span className='id'>账户ID：{userInfo.phoneNumber}</span>
                    <Button onClick={this.logout}>退出</Button>
                  </Fragment>
                )}
              </div>
            </div>
          </Header>
          <Content>
            <div className='content-box'>
              <Router>
                <Switch>
                  <Route exact path='/' component={Login} />
                  <Route exact path='/login' component={Login} />
                  <Route path='/assets' component={PageRouter} />
                  <Route exact path='/tip' component={Tip} />
                  <Route exact path='/download' component={Download} />
                  <Route exact path='/process' component={Process} />
                  <Route exact path='/about-us' component={AboutUs} />
                  <Route exact path='/contact-us' component={ContactUs} />
                  <Route exact path='/service-agreement' component={ServiceAgreement} />
                </Switch>
              </Router>
            </div>
          </Content>
          <Footer>
            <div className='footer-box'>
              <Row>
                <Col span={4} className='item'>
                  <Router>
                    <NavLink className='item-title' to='/about-us'>
                      关于我们
                    </NavLink>
                  </Router>
                  {/*<div className='item-text'>
                    <div>公司介绍</div>
                    <div>联系我们</div>
                    <div>免责声明</div>
                  </div>*/}
                </Col>
                <Col span={4} className='item'>
                  <Router>
                    <NavLink className='item-title' to='/contact-us'>
                      联系我们
                    </NavLink>
                  </Router>
                  {/*<div className='item-text'>
                    <div>如何注册</div>
                    <div>如何充值与提现</div>
                    <div>如何在线咨询</div>
                  </div>*/}
                </Col>
                <Col span={4} className='item'>
                  <Router>
                    <NavLink className='item-title' to='/service-agreement'>
                      服务协议
                    </NavLink>
                  </Router>
                  {/*<div className='item-text'>
                    <div>账户充值</div>
                    <div>资产管理</div>
                    <div>发行资产</div>
                  </div>*/}
                </Col>
                <Col span={4} className='item'>
                  <Router>
                    <NavLink className='item-title' to='/download'>
                      APP下载
                    </NavLink>
                  </Router>
                </Col>
                <Col span={4} />
                <Col span={4} className='online'>
                  <div className='item-title'>在线客服</div>
                  <div className='item-text'>周一至周五，09:00-18:00</div>
                </Col>
              </Row>
              <Divider />
              <p className='copyright'>{copyright}</p>
            </div>
          </Footer>
        </Layout>
      </div>
    )
  }
}
export default BaseRouter
