import React, { Component, Fragment } from 'react'
import { observer, inject } from 'mobx-react'
import { Link } from 'react-router-dom'
import { Row, Col, Card, Button, Divider, Table, message, Icon, Popover, Modal } from 'antd'
import './index.less'

import { config } from '../../../config'
import icon_delete from '../../../images/icon-delete.png'

const antIcon = <Icon type='loading' style={{ fontSize: 24 }} spin />
const col_txs = [
  {
    title: '时间',
    dataIndex: 'transTime',
    width: '15%'
  },
  {
    title: '类型',
    dataIndex: 'tradingType',
    width: '10%'
  },
  {
    title: '状态',
    dataIndex: 'tradingStatus',
    width: '5%',
    render: text => (
      <Fragment>
        {text === 0 && <span className='failed'>失败</span>}
        {text === 1 && <span className='success'>成功</span>}
        {text === 2 && <span className='pending'>打包</span>}
      </Fragment>
    )
  },
  {
    title: '对方地址',
    dataIndex: 'otherAddress',
    width: '46%'
  },
  {
    title: '数量',
    width: '12%',
    render: item => (
      <Popover content={item.amountDecimals}>
        <span className='amount'>{item.amountDecimalsClip}</span>
      </Popover>
    )
  },
  {
    title: '单位',
    dataIndex: 'symbol',
    width: '7%'
  },
  {
    title: '操作',
    width: '5%',
    render: item => (
      <span
        onClick={() => {
          window.open(`${config.explorer_txs}/${item.txHash}`)
        }}
      >
        详情
      </span>
    )
  }
]

@inject('userStore', 'assetsStore', 'verifyStore')
@observer
class Home extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentPage: 1,
      defaultSkip: 0,
      defaultLimitAssets: 5,
      defaultLimitRecords: 10,
      col_assets: [
        {
          title: '资产名称',
          dataIndex: 'assetName',
          width: '10%'
        },
        {
          title: '资产符号',
          dataIndex: 'assetSymbol',
          width: '10%'
        },
        {
          title: '代发行量',
          dataIndex: 'totalSupply',
          width: '40%'
        },
        {
          title: '发行状态',
          dataIndex: 'publishStatus',
          width: '10%',
          render: text => (
            <span>
              {text === 1 && '发布中'}
              {text === 2 && '发布成功'}
              {text === 3 && '发布失败'}
            </span>
          )
        },
        {
          title: '操作',
          width: '10%',
          render: item => (
            <div className='col-right'>
              {item.publishStatus === 2 && (
                <Link
                  to={{
                    pathname: `/assets/home/detail/${item.id}`
                  }}
                >
                  详情
                </Link>
              )}
              {item.publishStatus === 3 && (
                <img src={icon_delete} alt='icon_fail' onClick={() => this.delAsset(item.id)} />
              )}
            </div>
          )
        }
      ]
    }
  }

  componentWillMount() {
    const { history } = this.props
    const currentCardAddress = localStorage.getItem('currentCardAddress')

    if (!currentCardAddress) {
      message.warn('请先创建鲸卡')
      history.push('/process')
      return
    }
  }

  async componentDidMount() {
    const { defaultSkip, defaultLimitAssets, defaultLimitRecords } = this.state
    const { assetsStore, userStore } = this.props
    const { getAssetsList, tradingRecords } = assetsStore
    const { getCardList } = userStore

    await getCardList()

    const currentCardAddress = localStorage.getItem('currentCardAddress')

    if (currentCardAddress) {
      getAssetsList(currentCardAddress, defaultSkip, defaultLimitAssets)
      tradingRecords(currentCardAddress, defaultSkip, defaultLimitRecords)
    }
  }

  toDeploy = async () => {
    const { verifyStore } = this.props
    const { getVerificationStatus } = verifyStore
    let authenticationStatus = await getVerificationStatus()
    if (authenticationStatus === 1) {
      this.props.history.push('/assets/deploy')
    } else {
      message.info('请先完成实名认证')
      this.props.history.push('/assets/verify')
    }
  }

  delAsset = id => {
    const { doDelAssets, getAssetsList } = this.props.assetsStore
    const { defaultSkip, defaultLimitAssets } = this.state
    Modal.confirm({
      title: '确认删除该资产？',
      content: '删除操作不可撤销',
      onOk: async () => {
        let res = await doDelAssets(id)
        if (res) {
          const currentCardAddress = localStorage.getItem('currentCardAddress')
          getAssetsList(currentCardAddress, defaultSkip, defaultLimitAssets)
          this.setState({
            currentPage: 1
          })
        }
      },
      onCancel: () => {}
    })
  }

  handlerPaginationAssets = e => {
    const { defaultLimitAssets } = this.state
    const currentCardAddress = localStorage.getItem('currentCardAddress')
    const skip = (e.current - 1) * defaultLimitAssets
    const defaultLimit = defaultLimitAssets
    this.props.assetsStore.getAssetsList(currentCardAddress, skip, defaultLimit)
    this.setState({
      currentSkip: skip,
      currentPage: e.current
    })
  }

  handlerPaginationRecord = e => {
    const { defaultLimitRecords } = this.state
    const currentCardAddress = localStorage.getItem('currentCardAddress')
    const skip = (e.current - 1) * defaultLimitRecords
    const defaultLimit = this.state.defaultLimitRecords
    this.props.assetsStore.tradingRecords(currentCardAddress, skip, defaultLimit)
  }

  renderAssets = assetses => {
    let list = []
    for (let item of assetses) {
      list.push(
        <Row key={item.id}>
          <Col className='col-left' span={3}>
            <div className='col-left-name'>
              <Popover content={item.assetName}>
                <span>{item.assetName}</span>
              </Popover>
            </div>
          </Col>
          <Col span={7}>
            <div>{item.assetSymbol}</div>
          </Col>
          <Col span={7}>
            <div>{Number(item.totalSupply).toFixed(item.decimals)}</div>
          </Col>
          <Col className='col-right' span={5}>
            {item.publishStatus === 1 && <div>发布中</div>}
            {item.publishStatus === 2 && <div>发布成功</div>}
            {item.publishStatus === 3 && <div>发布失败</div>}
          </Col>
          <Col className='col-right' span={2}>
            {item.publishStatus === 2 && (
              <Link
                to={{
                  pathname: `/assets/home/detail/${item.id}`
                }}
              >
                详情
              </Link>
            )}
            {item.publishStatus === 3 && (
              <img src={icon_delete} alt='icon_fail' onClick={() => this.delAsset(item.id)} />
            )}
          </Col>
        </Row>
      )
    }
    return list
  }

  render() {
    const { assetsStore } = this.props
    const { col_assets, currentPage, defaultLimitAssets, defaultLimitRecords } = this.state
    const { records, isLoading, recordsTotal, assetsList, assetsTotal } = assetsStore
    return (
      <div className='home'>
        <Card className='contain'>
          <div className='h1-box'>
            <h1>我发布的资产</h1>
            <Button onClick={this.toDeploy}>发布资产</Button>
          </div>
          <div>
            <Table
              className='assets'
              rowKey={item => item.id}
              columns={col_assets}
              dataSource={assetsList}
              pagination={{
                current: currentPage,
                pageSize: defaultLimitAssets,
                total: assetsTotal,
                showTotal: () => {
                  return `共${assetsTotal}个资产`
                },
                hideOnSinglePage: true
              }}
              onChange={this.handlerPaginationAssets}
              loading={{ spinning: isLoading, indicator: antIcon, size: 'large' }}
            />
          </div>
          <Divider />
          <div>
            <h1>交易记录</h1>
            <Table
              className='records'
              rowKey={record => record.txHash}
              columns={col_txs}
              dataSource={records}
              pagination={{
                pageSize: defaultLimitRecords,
                total: recordsTotal,
                showTotal: () => {
                  return `共${recordsTotal}条`
                },
                showQuickJumper: true,
                hideOnSinglePage: true
              }}
              onChange={this.handlerPaginationRecord}
              loading={{ spinning: isLoading, indicator: antIcon, size: 'large' }}
            />
          </div>
        </Card>
      </div>
    )
  }
}

export default Home
