const areaCode = [
  { area: '中国', code: '86', flag: '🇨🇳' },
  { area: '阿尔巴尼亚', code: '355', flag: '🇦🇱' },
  { area: '阿尔及利亚', code: '213', flag: '🇩🇿' },
  { area: '阿富汗', code: '93', flag: '🇦🇫' },
  { area: '阿根廷', code: '54', flag: '🇦🇷' },
  { area: '阿拉伯联合酋长国', code: '971', flag: '🇦🇪' },
  { area: '阿鲁巴', code: '297', flag: '🇦🇼' },
  { area: '阿曼', code: '968', flag: '🇴🇲' },
  { area: '阿塞拜疆', code: '994', flag: '🇦🇿' },
  { area: '阿森松岛', code: '247', flag: '🇦🇨' },
  { area: '埃及', code: '20', flag: '🇪🇬' },
  { area: '埃塞俄比亚', code: '251', flag: '🇪🇹' },
  { area: '爱尔兰', code: '353', flag: '🇮🇪' },
  { area: '爱沙尼亚', code: '372', flag: '🇪🇪' },
  { area: '安道尔', code: '376', flag: '🇦🇩' },
  { area: '安哥拉', code: '244', flag: '🇦🇴' },
  { area: '安圭拉', code: '1', flag: '🇦🇮' },
  { area: '安提瓜和巴布达', code: '1', flag: '🇦🇬' },
  { area: '奥地利', code: '43', flag: '🇦🇹' },
  { area: '澳大利亚', code: '61', flag: '🇦🇺' },
  { area: '澳门', code: '853', flag: '🇲🇴' },
  { area: '巴巴多斯', code: '1', flag: '🇧🇧' },
  { area: '巴布亚新几内亚', code: '675', flag: '🇵🇬' },
  { area: '巴哈马', code: '1', flag: '🇧🇸' },
  { area: '巴基斯坦', code: '92', flag: '🇵🇰' },
  { area: '巴拉圭', code: '595', flag: '🇵🇾' },
  { area: '巴勒斯坦', code: '970', flag: '🇵🇸' },
  { area: '巴林', code: '973', flag: '🇧🇭' },
  { area: '巴拿马', code: '507', flag: '🇵🇦' },
  { area: '巴西', code: '55', flag: '🇧🇷' },
  { area: '白俄罗斯', code: '375', flag: '🇧🇾' },
  { area: '百慕大', code: '1', flag: '🇧🇲' },
  { area: '保加利亚', code: '359', flag: '🇧🇬' },
  { area: '北马里亚纳群岛', code: '1', flag: '🇲🇵' },
  { area: '北马其顿', code: '389', flag: '🇲🇰' },
  { area: '贝宁', code: '229', flag: '🇧🇯' },
  { area: '比利时', code: '32', flag: '🇧🇪' },
  { area: '冰岛', code: '354', flag: '🇮🇸' },
  { area: '波多黎各', code: '1', flag: '🇵🇷' },
  { area: '波兰', code: '48', flag: '🇵🇱' },
  { area: '波斯尼亚和黑塞哥维那', code: '387', flag: '🇧🇦' },
  { area: '玻利维亚', code: '591', flag: '🇧🇴' },
  { area: '伯利兹', code: '501', flag: '🇧🇿' },
  { area: '博茨瓦纳', code: '267', flag: '🇧🇼' },
  { area: '不丹', code: '975', flag: '🇧🇹' },
  { area: '布基纳法索', code: '226', flag: '🇧🇫' },
  { area: '布隆迪', code: '257', flag: '🇧🇮' },
  { area: '朝鲜', code: '850', flag: '🇰🇵' },
  { area: '赤道几内亚', code: '240', flag: '🇬🇶' },
  { area: '丹麦', code: '45', flag: '🇩🇰' },
  { area: '德国', code: '49', flag: '🇩🇪' },
  { area: '东帝汶', code: '670', flag: '🇹🇱' },
  { area: '多哥', code: '228', flag: '🇹🇬' },
  { area: '多米尼加共和国', code: '1', flag: '🇩🇴' },
  { area: '多米尼克', code: '1', flag: '🇩🇲' },
  { area: '俄罗斯', code: '7', flag: '🇷🇺' },
  { area: '厄瓜多尔', code: '593', flag: '🇪🇨' },
  { area: '厄立特里亚', code: '291', flag: '🇪🇷' },
  { area: '法国', code: '33', flag: '🇫🇷' },
  { area: '法罗群岛', code: '298', flag: '🇫🇴' },
  { area: '法属波利尼西亚', code: '689', flag: '🇵🇫' },
  { area: '法属圭亚那', code: '594', flag: '🇬🇫' },
  { area: '法属圣马丁', code: '590', flag: '🇫🇷' },
  { area: '梵蒂冈', code: '39', flag: '🇻🇦' },
  { area: '菲律宾', code: '63', flag: '🇵🇭' },
  { area: '斐济', code: '679', flag: '🇫🇯' },
  { area: '芬兰', code: '358', flag: '🇫🇮' },
  { area: '佛得角', code: '238', flag: '🇨🇻' },
  { area: '福克兰群岛', code: '500', flag: '🇫🇰' },
  { area: '冈比亚', code: '220', flag: '🇬🇲' },
  { area: '刚果（布）', code: '242', flag: '🇨🇬' },
  { area: '刚果（金）', code: '243', flag: '🇨🇩' },
  { area: '哥伦比亚', code: '57', flag: '🇨🇴' },
  { area: '哥斯达黎加', code: '506', flag: '🇨🇷' },
  { area: '格林纳达', code: '1', flag: '🇬🇩' },
  { area: '格陵兰', code: '299', flag: '🇬🇱' },
  { area: '格鲁吉亚', code: '995', flag: '🇬🇪' },
  { area: '古巴', code: '53', flag: '🇨🇺' },
  { area: '瓜德罗普', code: '590', flag: '🇬🇵' },
  { area: '关岛', code: '1', flag: '🇬🇺' },
  { area: '圭亚那', code: '592', flag: '🇬🇾' },
  { area: '哈萨克斯坦', code: '7', flag: '🇰🇿' },
  { area: '海地', code: '509', flag: '🇭🇹' },
  { area: '韩国', code: '82', flag: '🇰🇷' },
  { area: '荷兰', code: '31', flag: '🇳🇱' },
  { area: '荷属加勒比区', code: '599', flag: '🇧🇶' },
  { area: '荷属圣马丁', code: '1', flag: '🇸🇽' },
  { area: '黑山', code: '382', flag: '🇲🇪' },
  { area: '洪都拉斯', code: '504', flag: '🇭🇳' },
  { area: '基里巴斯', code: '686', flag: '🇰🇮' },
  { area: '吉布提', code: '253', flag: '🇩🇯' },
  { area: '吉尔吉斯斯坦', code: '996', flag: '🇰🇬' },
  { area: '几内亚', code: '224', flag: '🇬🇳' },
  { area: '几内亚比绍', code: '245', flag: '🇬🇼' },
  { area: '加拿大', code: '1', flag: '🇨🇦' },
  { area: '加纳', code: '233', flag: '🇬🇭' },
  { area: '加蓬', code: '241', flag: '🇬🇦' },
  { area: '柬埔寨', code: '855', flag: '🇰🇭' },
  { area: '捷克', code: '420', flag: '🇨🇿' },
  { area: '津巴布韦', code: '263', flag: '🇿🇼' },
  { area: '喀麦隆', code: '237', flag: '🇨🇲' },
  { area: '卡塔尔', code: '974', flag: '🇶🇦' },
  { area: '开曼群岛', code: '1', flag: '🇰🇾' },
  { area: '科摩罗', code: '269', flag: '🇰🇲' },
  { area: '科索沃', code: '383', flag: '🇽🇰' },
  { area: '科特迪瓦', code: '225', flag: '🇨🇮' },
  { area: '科威特', code: '965', flag: '🇰🇼' },
  { area: '克罗地亚', code: '385', flag: '🇭🇷' },
  { area: '肯尼亚', code: '254', flag: '🇰🇪' },
  { area: '库克群岛', code: '682', flag: '🇨🇰' },
  { area: '库拉索', code: '599', flag: '🇨🇼' },
  { area: '拉脱维亚', code: '371', flag: '🇱🇻' },
  { area: '莱索托', code: '266', flag: '🇱🇸' },
  { area: '老挝', code: '856', flag: '🇱🇦' },
  { area: '黎巴嫩', code: '961', flag: '🇱🇧' },
  { area: '立陶宛', code: '370', flag: '🇱🇹' },
  { area: '利比里亚', code: '231', flag: '🇱🇷' },
  { area: '利比亚', code: '218', flag: '🇱🇾' },
  { area: '列支敦士登', code: '423', flag: '🇱🇮' },
  { area: '留尼汪', code: '262', flag: '🇷🇪' },
  { area: '卢森堡', code: '352', flag: '🇱🇺' },
  { area: '卢旺达', code: '250', flag: '🇷🇼' },
  { area: '罗马尼亚', code: '40', flag: '🇷🇴' },
  { area: '马达加斯加', code: '261', flag: '🇲🇬' },
  { area: '马尔代夫', code: '960', flag: '🇲🇻' },
  { area: '马耳他', code: '356', flag: '🇲🇹' },
  { area: '马拉维', code: '265', flag: '🇲🇼' },
  { area: '马来西亚', code: '260', flag: '🇲🇾' },
  { area: '马里', code: '223', flag: '🇲🇱' },
  { area: '马绍尔群岛', code: '692', flag: '🇲🇭' },
  { area: '马提尼克', code: '596', flag: '🇲🇶' },
  { area: '毛里求斯', code: '230', flag: '🇲🇺' },
  { area: '毛里塔尼亚', code: '222', flag: '🇲🇷' },
  { area: '美国', code: '1', flag: '🇺🇸' },
  { area: '美属萨摩亚', code: '1', flag: '🇦🇸' },
  { area: '美属维尔京群岛', code: '1', flag: '🇻🇮' },
  { area: '蒙古', code: '976', flag: '🇲🇳' },
  { area: '蒙特塞拉特', code: '1', flag: '🇲🇸' },
  { area: '孟加拉国', code: '880', flag: '🇧🇩' },
  { area: '秘鲁', code: '51', flag: '🇵🇪' },
  { area: '密克罗尼西亚', code: '691', flag: '🇫🇲' },
  { area: '缅甸', code: '95', flag: '🇲🇲' },
  { area: '摩尔多瓦', code: '373', flag: '🇲🇩' },
  { area: '摩洛哥', code: '212', flag: '🇲🇦' },
  { area: '摩纳哥', code: '377', flag: '🇲🇨' },
  { area: '莫桑比克', code: '258', flag: '🇲🇿' },
  { area: '墨西哥', code: '52', flag: '🇲🇽' },
  { area: '纳米比亚', code: '264', flag: '🇳🇦' },
  { area: '南非', code: '27', flag: '🇿🇦' },
  { area: '南苏丹', code: '211', flag: '🇸🇸' },
  { area: '瑙鲁', code: '674', flag: '🇳🇷' },
  { area: '尼加拉瓜', code: '505', flag: '🇳🇮' },
  { area: '尼泊尔', code: '577', flag: '🇳🇵' },
  { area: '尼日尔', code: '227', flag: '🇳🇪' },
  { area: '尼日利亚', code: '234', flag: '🇳🇬' },
  { area: '纽埃', code: '683', flag: '🇳🇺' },
  { area: '挪威', code: '47', flag: '🇳🇴' },
  { area: '诺福克岛', code: '672', flag: '🇳🇫' },
  { area: '帕劳', code: '680', flag: '🇵🇼' },
  { area: '葡萄牙', code: '351', flag: '🇵🇹' },
  { area: '日本', code: '81', flag: '🇯🇵' },
  { area: '瑞典', code: '46', flag: '🇸🇪' },
  { area: '瑞士', code: '41', flag: '🇨🇭' },
  { area: '萨尔瓦多', code: '503', flag: '🇸🇻' },
  { area: '萨摩亚', code: '685', flag: '🇼🇸' },
  { area: '塞尔维亚', code: '381', flag: '🇷🇸' },
  { area: '塞拉利昂', code: '232', flag: '🇸🇱' },
  { area: '塞内加尔', code: '221', flag: '🇸🇳' },
  { area: '塞浦路斯', code: '357', flag: '🇨🇾' },
  { area: '塞舌尔', code: '248', flag: '🇸🇨' },
  { area: '沙特阿拉伯', code: '966', flag: '🇸🇦' },
  { area: '圣巴泰勒米', code: '590', flag: '🇫🇷' },
  { area: '圣多美和普林西比', code: '239', flag: '🇸🇹' },
  { area: '圣赫勒拿', code: '290', flag: '🇸🇭' },
  { area: '圣基茨和尼维斯', code: '1', flag: '🇰🇳' },
  { area: '圣卢西亚', code: '1', flag: '🇱🇨' },
  { area: '圣马力诺', code: '378', flag: '🇸🇲' },
  { area: '圣皮埃尔和密克隆', code: '508', flag: '🇵🇲' },
  { area: '圣文森特和格林纳丁斯', code: '1', flag: '🇻🇨' },
  { area: '斯里兰卡', code: '94', flag: '🇱🇰' },
  { area: '斯洛伐克', code: '421', flag: '🇸🇰' },
  { area: '斯洛文尼亚', code: '386', flag: '🇸🇮' },
  { area: '斯威士兰', code: '268', flag: '🇸🇿' },
  { area: '苏丹', code: '249', flag: '🇸🇩' },
  { area: '苏里南', code: '597', flag: '🇸🇷' },
  { area: '所罗门群岛', code: '677', flag: '🇸🇧' },
  { area: '索马里', code: '252', flag: '🇸🇴' },
  { area: '塔吉克斯坦', code: '992', flag: '🇹🇯' },
  { area: '泰国', code: '66', flag: '🇹🇭' },
  { area: '坦桑尼亚', code: '255', flag: '🇹🇿' },
  { area: '汤加', code: '676', flag: '🇹🇴' },
  { area: '特克斯和凯科斯群岛', code: '1', flag: '🇹🇨' },
  { area: '特立尼达和多巴哥', code: '1', flag: '🇹🇹' },
  { area: '突尼斯', code: '216', flag: '🇹🇳' },
  { area: '图瓦卢', code: '688', flag: '🇹🇻' },
  { area: '土耳其', code: '90', flag: '🇹🇷' },
  { area: '土库曼斯坦', code: '993', flag: '🇹🇲' },
  { area: '托克劳', code: '690', flag: '🇹🇰' },
  { area: '瓦利斯和富图纳', code: '681', flag: '🇫🇷' },
  { area: '瓦努阿图', code: '678', flag: '🇻🇺' },
  { area: '危地马拉', code: '502', flag: '🇬🇹' },
  { area: '委内瑞拉', code: '58', flag: '🇻🇪' },
  { area: '文莱', code: '673', flag: '🇧🇳' },
  { area: '乌干达', code: '256', flag: '🇺🇬' },
  { area: '乌克兰', code: '380', flag: '🇺🇦' },
  { area: '乌拉圭', code: '598', flag: '🇺🇾' },
  { area: '乌兹别克斯坦', code: '998', flag: '🇺🇿' },
  { area: '西班牙', code: '34', flag: '🇪🇸' },
  { area: '希腊', code: '30', flag: '🇬🇷' },
  { area: '新加坡', code: '65', flag: '🇸🇬' },
  { area: '新喀里多尼亚', code: '687', flag: '🇳🇨' },
  { area: '新西兰', code: '64', flag: '🇳🇿' },
  { area: '匈牙利', code: '36', flag: '🇭🇺' },
  { area: '叙利亚', code: '963', flag: '🇸🇾' },
  { area: '牙买加', code: '1', flag: '🇯🇲' },
  { area: '亚美尼亚', code: '374', flag: '🇦🇲' },
  { area: '也门', code: '967', flag: '🇾🇪' },
  { area: '伊拉克', code: '964', flag: '🇮🇶' },
  { area: '伊朗', code: '98', flag: '🇮🇷' },
  { area: '以色列', code: '972', flag: '🇮🇱' },
  { area: '意大利', code: '39', flag: '🇮🇹' },
  { area: '印度', code: '91', flag: '🇮🇳' },
  { area: '印度尼西亚', code: '62', flag: '🇮🇩' },
  { area: '英国', code: '44', flag: '🇬🇧' },
  { area: '英属维尔京群岛', code: '1', flag: '🇻🇬' },
  { area: '英属印度洋领地', code: '246', flag: '🇬🇧' },
  { area: '约旦', code: '962', flag: '🇯🇴' },
  { area: '越南', code: '84', flag: '🇻🇳' },
  { area: '赞比亚', code: '260', flag: '🇿🇲' },
  { area: '乍得', code: '235', flag: '🇹🇩' },
  { area: '直布罗陀', code: '350', flag: '🇬🇮' },
  { area: '智利', code: '56', flag: '🇨🇱' },
  { area: '中非共和国', code: '236', flag: '🇨🇫' },
]

export { areaCode }
