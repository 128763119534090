import { observable, action } from 'mobx'
import { getFormatDate, compareDate } from '../../common/tool'
import { message } from 'antd'
import id_front from '../../images/id-front.png'
import id_back from '../../images/id-back.png'
import { query, mutation } from '../../common/apollo'

export default class UserStore {
  /* obserable */
  @observable
  current_personal = 0

  @observable
  current_enterprise = 0

  @observable
  id_front_img = id_front

  @observable
  id_back_img = id_back

  @observable
  id_back_info = {}

  @observable
  id_front_info = {}

  @observable
  personal_verification_info = {}

  @observable
  company_verification_info = {}

  @observable
  company_request_info = {}

  @observable
  use_input_name = ''

  @observable
  use_input_email = ''

  @observable
  use_input_idnum = ''

  @observable
  company_input_info = {
    company_input_name: '',
    company_input_username: '',
    company_input_email: '',
    company_input_idnum: '',
    company_input_idimg: '',
    company_input_addinfo: '',
    company_input_userbankname: '',
    company_input_bank: '',
    company_input_banknum: '',
    company_input_contacter_phone: ''
  }

  @observable
  isAccord = false

  @observable
  isAccordCom = false

  @observable
  isInvalid = false

  @observable
  upFrontLoading = false

  @observable
  upBackLoading = false

  @observable
  upCompanyLoading = false

  /* action */
  @action
  nextPersonal = async () => {
    let name = this.use_input_name
    let clientIDNo = this.id_front_info.clientIDNo
    let identityCardFrontInfoID = this.id_front_info.id
    let identityCardBackInfoID = this.id_back_info.id
    let res = await this.personalCertificate(
      name,
      clientIDNo,
      identityCardFrontInfoID,
      identityCardBackInfoID
    )
    if (res) {
      this.current_personal++
    }
  }

  @action
  nextEnterprise = async () => {
    let companyName = this.company_input_info.company_input_name
    let registrationNo = this.company_input_info.company_input_idnum
    let contacter = this.company_input_info.company_input_username
    let phoneNumberOfContacter = this.company_input_info.company_input_contacter_phone
    let email = this.company_input_info.company_input_email
    let additionalAuthentication = this.company_input_info.company_input_addinfo
    let accountName = this.company_input_info.company_input_userbankname
    let corporateAccountOfDepositBank = this.company_input_info.company_input_bank
    let corporateAccountNo = this.company_input_info.company_input_banknum
    let bussinessLicenseInfoID = this.company_request_info.id
    let identityCardFrontInfoID = this.id_front_info.id
    let identityCardBackInfoID = this.id_back_info.id
    let res = await this.companyCertificate(
      companyName,
      registrationNo,
      contacter,
      phoneNumberOfContacter,
      email,
      additionalAuthentication,
      accountName,
      corporateAccountOfDepositBank,
      corporateAccountNo,
      bussinessLicenseInfoID,
      identityCardFrontInfoID,
      identityCardBackInfoID
    )
    if (res) {
      this.current_enterprise++
    }
  }

  @action
  reset = () => {
    this.current_personal = 1
    this.current_enterprise = 1
    this.id_back_img = id_back
    this.id_front_img = id_front
    this.id_front_info = {}
    this.id_back_info = {}
    this.use_input_name = ''
    this.use_input_email = ''
    this.use_input_idnum = ''
    this.isAccord = false
    this.isAccordCom = false
    this.isInvalid = false
    this.company_input_info = {
      company_input_name: '',
      company_input_username: '',
      company_input_email: '',
      company_input_idnum: '',
      company_input_idimg: '',
      company_input_addinfo: '',
      company_input_userbankname: '',
      company_input_bank: '',
      company_input_banknum: '',
      company_input_contacter_phone: ''
    }
  }

  @action
  IDFrontInfo = async img => {
    const body = `
      mutation IDFrontInfo(
        $img: String!
      ){
        resolveFrontOfIdentityCard(
          img:$img
        ){
          id
          imgURL
          clientIDNo
          name
          sex
          ethnicity
          birth
          residentialAddress
        }
      }`
    const variables = {
      img: img
    }
    let res = await mutation(body, variables)
    if (res.errors) {
      this.upFrontLoading = false
      message.error('身份证上传错误')
      return false
    }
    if (!res.resolveFrontOfIdentityCard.clientIDNo) {
      this.upFrontLoading = false
      message.error('身份证上传错误')
      return false
    }
    this.upFrontLoading = false
    message.success('身份证上传成功')
    return res.resolveFrontOfIdentityCard
  }

  @action
  IDBackInfo = async img => {
    const body = `
      mutation IDBackInfo(
        $img: String!
      ){
        resolveBackOfIdentityCard(
          img:$img
        ){
          id
          imgURL
          authority
          issueDate
          invalidDate
        }
      }`
    const variables = {
      img: img
    }
    let res = await mutation(body, variables)
    if (res.errors) {
      this.upBackLoading = false
      message.error('身份证上传错误')
      return false
    }
    if (!res.resolveBackOfIdentityCard.issueDate) {
      this.upBackLoading = false
      message.error('身份证上传错误')
      return false
    }
    this.upBackLoading = false
    message.success('身份证上传成功')
    return res.resolveBackOfIdentityCard
  }

  @action
  IDCompanyInfo = async img => {
    const body = `
      mutation IDCompanyInfo(
        $img: String!
      ){
        resolveBussinessLicense(
          img:$img
        ){
          id
          imgURL
          registrationNo
          unifiedSocialCreditCode
          enterpriseName
          typeOfEnterprise
          residence
          legalRepresentative
          registrationNo
          dateOfEstablishment
          termOfBusiness
          scopeOfBusiness
          typeOfEnterprise
        }
      }`
    const variables = {
      img: img
    }
    let res = await mutation(body, variables)
    if (res.errors) {
      this.upCompanyLoading = false
      message.error('营业执照上传错误')
      return false
    }
    this.upCompanyLoading = false
    message.success('营业执照上传成功')
    return res.resolveBussinessLicense
  }

  @action
  getIdComapnyInfo = async image => {
    image = image.replace('data:image/png;base64,', '')
    image = image.replace('data:image/jpg;base64,', '')
    image = image.replace('data:image/jpeg;base64,', '')
    this.upCompanyLoading = true
    this.company_request_info = await this.IDCompanyInfo(image)
  }

  @action
  getIdCardInfo = async (image, idside) => {
    image = image.replace('data:image/png;base64,', '')
    image = image.replace('data:image/jpg;base64,', '')
    image = image.replace('data:image/jpeg;base64,', '')
    if (idside === 'front') {
      this.upFrontLoading = true
      this.id_front_info = await this.IDFrontInfo(image, 'front')
      if (
        this.use_input_name !== this.id_front_info.name &&
        this.use_input_idnum !== this.id_front_info.clientIDNo
      ) {
        this.isAccord = true
      } else {
        this.isAccord = false
      }
      if (this.company_input_info['company_input_username'] !== this.id_front_info.name) {
        this.isAccordCom = true
      } else {
        this.isAccordCom = false
      }
    } else {
      this.upBackLoading = true
      this.id_back_info = await this.IDBackInfo(image, 'back')
      let starttime = getFormatDate()
      let endtime = this.id_back_info.invalidData
      if (compareDate(starttime, endtime)) {
        this.isInvalid = true
      } else {
        this.isInvalid = false
      }
    }
  }

  @action
  personalCertificate = async (
    name,
    clientIDNo,
    identityCardFrontInfoID,
    identityCardBackInfoID
  ) => {
    const body = `
      mutation personal(
        $name: String!
        $clientIDNo: String!
        $identityCardFrontInfoID: String!
        $identityCardBackInfoID: String!
      ){
        personalCertificate(
          name:$name
          clientIDNo:$clientIDNo
          identityCardFrontInfoID:$identityCardFrontInfoID
          identityCardBackInfoID:$identityCardBackInfoID
        ){
          id
          name
          clientIDNo
          idCardFrontImgURL
          idCardBackImgURL
        }
      }`
    const variables = {
      name: name,
      clientIDNo: clientIDNo,
      identityCardFrontInfoID: identityCardFrontInfoID,
      identityCardBackInfoID: identityCardBackInfoID
    }
    let res = await mutation(body, variables)
    if (res.errors) {
      message.error(res.errors[0].message)
      return false
    }
    this.personal_verification_info = res.personalCertificate
    message.success('信息提交成功')
    return res.personalCertificate
  }

  @action
  companyCertificate = async (
    companyName,
    registrationNo,
    contacter,
    phoneNumberOfContacter,
    email,
    additionalAuthentication,
    accountName,
    corporateAccountOfDepositBank,
    corporateAccountNo,
    bussinessLicenseInfoID,
    identityCardFrontInfoID,
    identityCardBackInfoID
  ) => {
    const body = `
      mutation personal(
        $companyName: String!
        $registrationNo: String!
        $contacter: String!
        $phoneNumberOfContacter: String!
        $email: String!
        $additionalAuthentication: String!
        $accountName: String!
        $corporateAccountOfDepositBank: String!
        $corporateAccountNo: String!
        $bussinessLicenseInfoID: String!
        $identityCardFrontInfoID: String!
        $identityCardBackInfoID: String!
      ){
        companyCertificate(
          companyName:$companyName
          registrationNo:$registrationNo
          contacter:$contacter
          phoneNumberOfContacter:$phoneNumberOfContacter
          email:$email
          additionalAuthentication:$additionalAuthentication
          accountName:$accountName
          corporateAccountOfDepositBank:$corporateAccountOfDepositBank
          corporateAccountNo:$corporateAccountNo
          bussinessLicenseInfoID:$bussinessLicenseInfoID
          identityCardFrontInfoID:$identityCardFrontInfoID
          identityCardBackInfoID:$identityCardBackInfoID
        ){
          id
          companyName
          registrationNo
          contacter
          phoneNumberOfContacter
          email
          additionalAuthentication
          accountName
          corporateAccountOfDepositBank
          corporateAccountNo
          bussinessLicenseImgURL
          idCardBackImgURL
          idCardFrontImgURL
        }
      }`
    const variables = {
      companyName: companyName,
      registrationNo: registrationNo,
      contacter: contacter,
      phoneNumberOfContacter: phoneNumberOfContacter,
      email: email,
      additionalAuthentication: additionalAuthentication,
      accountName: accountName,
      corporateAccountOfDepositBank: corporateAccountOfDepositBank,
      corporateAccountNo: corporateAccountNo,
      bussinessLicenseInfoID: bussinessLicenseInfoID,
      identityCardFrontInfoID: identityCardFrontInfoID,
      identityCardBackInfoID: identityCardBackInfoID
    }
    let res = await mutation(body, variables)
    if (res.errors) {
      message.error(res.errors[0].message)
      return false
    }
    this.company_verification_info = res.companyCertificate
    message.success('信息提交成功')
    return res.companyCertificate
  }

  @action
  getUserInputName = name => {
    this.use_input_name = name
    if (this.use_input_name !== this.id_front_info.name && this.id_front_info.name) {
      this.isAccord = true
    } else {
      this.isAccord = false
    }
  }

  @action
  getUserInputEmail = email => {
    this.use_input_email = email
  }

  @action
  getUserInputIdnum = idnum => {
    this.use_input_idnum = idnum
    if (this.use_input_idnum !== this.id_front_info.clientIDNo && this.id_front_info.clientIDNo) {
      this.isAccord = true
    } else {
      this.isAccord = false
    }
  }

  @action
  getCompanyInfo = (key, info) => {
    if (key === 'company_input_username') {
      if (info !== this.id_front_info.name) {
        this.isAccordCom = true
      } else {
        this.isAccordCom = false
      }
    }
    this.company_input_info[key] = info
  }

  @action
  getVerificationStatus = async () => {
    const body = `
    query{
      user{
        phoneNumber
        certificationStatuses{
          personalCertificationInfo{
            name
            clientIDNo
            idCardBackImgURL
            idCardFrontImgURL
          }
          companyCertificationInfo{
            companyName
            registrationNo
            contacter
            phoneNumberOfContacter
            email
            additionalAuthentication
            accountName
            corporateAccountOfDepositBank
            corporateAccountNo
            bussinessLicenseImgURL
            idCardBackImgURL
            idCardFrontImgURL
          }
          certType
          certStatus
        }
      }
    }`
    const variables = null
    let res = await query(body, variables)
    if (res) {
      let data = res.user
      if (res.errors) {
        message.error(res.errors[0].message)
        return
      }
      if (data.certificationStatuses.length === 0) {
        return
      }
      for (let i = 0; i < data.certificationStatuses.length; i++) {
        let type = data.certificationStatuses[i].certType // 1个人 2企业
        var status = data.certificationStatuses[i].certStatus // 1已认证 2认证中
        let personal = data.certificationStatuses[i].personalCertificationInfo
        let company = data.certificationStatuses[i].companyCertificationInfo
        let phone = data.phoneNumber
        if (type === 1 && personal) {
          this.current_personal = 2
          this.personal_verification_info = personal
          this.personal_verification_info['phoneNumber'] = phone
          this.personal_verification_info['status'] = status
        }
        if (type === 2 && company) {
          status === 1 ? (this.current_enterprise = 3) : (this.current_enterprise = 2)
          this.company_verification_info = company
          this.company_verification_info['phoneNumber'] = phone
          this.company_verification_info['status'] = status
        }
      }
      return status
    }
  }
}
