import React, { Component } from 'react'
import './document.less'

class ContactUs extends Component {
  render() {
    return (
      <div className='document-wrp'>
        <h3>联系我们</h3>
        <div className="flex-dom">
          <div>
            <p>上海域乎信息技术有限公司</p>
            <p>www.yuhu.tech</p>
            <p>邮编：200050</p>
            <p>地址：上海市长宁区长宁路1033号联通大厦909室</p>
          </div>
          <div>
            <p>商务合作</p>
            <p>Email：hebe@yuhu.tech</p>
            <p>WeChat：hebe660805</p>
            <p>Tel：13564655303(hebe)</p>
          </div>
          <div>
            <p>市场合作</p>
            <p>Email：marketing@yuhu.tech</p>
            <p>WeChat：243589123</p>
            <p>Tel: 13779932672（Vera）</p>
          </div>
        </div>
      </div>
    )
  }
}

export default ContactUs
