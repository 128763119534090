import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import { Card, Select, Icon, message } from 'antd'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import './index.less'

import { config } from '../../config'

const Option = Select.Option

@inject('userStore', 'assetsStore', 'deployStore', 'dropStore')
@observer
class SideCard extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  async componentDidMount() {
    const { userStore } = this.props
    const { getCardList } = userStore
    await getCardList()
  }

  dropDownCard = async () => {
    const { userStore } = this.props
    const { getCardList } = userStore
    await getCardList()
  }

  changeCard = async e => {
    const { userStore } = this.props
    const { cardList, updateCurrentCard } = userStore
    const defaultSkip = 0
    const defaultLimit = 10
    const currentCardIndex = e
    const currentCardAddress = cardList[e].addressString
    const currentCardName = cardList[e].nickname

    await updateCurrentCard(currentCardIndex)
    this.updatePageHome(currentCardAddress, defaultSkip, defaultLimit)
    this.updatePageDeploy(currentCardAddress, currentCardName)
    this.updatePageAirdrop(currentCardAddress, defaultSkip, defaultLimit)
    this.matchRoute()
  }

  updatePageAirdrop = async (currentCardAddress, defaultSkip, defaultLimit) => {
    const { dropStore } = this.props
    const { getDropList } = dropStore

    await getDropList(currentCardAddress, defaultSkip, defaultLimit)
  }

  updatePageDeploy = async (currentCardAddress, currentCardName) => {
    const { deployStore } = this.props
    const { setDeployCardInfo } = deployStore

    await setDeployCardInfo(currentCardAddress, currentCardName)
  }

  updatePageHome = async (currentCardAddress, defaultSkip, defaultLimit) => {
    const { assetsStore } = this.props
    const { getAssetsList, tradingRecords } = assetsStore

    await getAssetsList(currentCardAddress, defaultSkip, defaultLimit)
    await tradingRecords(currentCardAddress, defaultSkip)
  }

  matchRoute = () => {
    let route = window.location.hash
    if (route.startsWith('#/assets/home/detail')) {
      window.location.href = `${config.host_route}/#/assets/home`
      return
    }
    if (route.startsWith('#/assets/airdrop')) {
      window.location.href = `${config.host_route}/#/assets/airdrop/list`
      return
    }
  }

  render() {
    const { userStore } = this.props
    const { cardList } = userStore
    const currentCardIndex = localStorage.getItem('currentCardIndex')
    return (
      <div className="card">
        <Card>
          <div className="card-top">
            <span>
              {cardList.length > 0
                ? cardList[currentCardIndex || 0].nickname
                : ''}
            </span>
            <Select
              style={{ width: 90 }}
              placeholder={
                cardList.length > 0
                  ? cardList[currentCardIndex || 0].nickname
                  : ''
              }
              suffixIcon={<Icon type="more" />}
              onChange={this.changeCard}
              onDropdownVisibleChange={this.dropDownCard}
            >
              {cardList.map((item, index) => {
                return (
                  <Option value={index} key={item.id}>
                    {item.nickname ? item.nickname : ''}
                  </Option>
                )
              })}
            </Select>
          </div>
          <div className="card-btm">
            <span>
              {cardList.length > 0
                ? cardList[currentCardIndex].addressString.substr(0, 10) +
                  '...' +
                  cardList[currentCardIndex].addressString.substr(36)
                : ''}
            </span>
            <CopyToClipboard
              text={
                cardList.length > 0
                  ? cardList[currentCardIndex].addressString
                  : ''
              }
              onCopy={() => {
                message.success('已复制')
              }}
            >
              <span className="copy-btn">复制</span>
            </CopyToClipboard>
          </div>
        </Card>
      </div>
    )
  }
}

export default SideCard
