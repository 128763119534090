import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import { Row, Col, Card, Steps, Button, Input, message, Spin, Icon, Select } from 'antd'
import { getFormatDate, compareDate, checkIdNum, getImgBase64 } from '../../../common/tool'
import './index.less'

import pass from '../../../images/icon-pass.png'
import pending from '../../../images/icon-examine.png'
import id_front from '../../../images/id-front.png'
import id_back from '../../../images/id-back.png'

const antIcon = <Icon type='loading' style={{ fontSize: 24 }} spin />
const Step = Steps.Step
const Option = Select.Option

@inject('verifyStore', 'userStore')
@observer
class Personal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ID_front_img: id_front,
      ID_back_img: id_back,
      index: 0
    }
  }

  async componentDidMount() {
    const { verifyStore } = this.props
    const { reset, getVerificationStatus } = verifyStore
    reset()
    getVerificationStatus()
  }

  renderSteps = () => {
    const { verifyStore } = this.props
    const { current_personal } = verifyStore
    return (
      <Row>
        <Col span={22} offset={1}>
          <Steps labelPlacement='vertical' current={current_personal}>
            <Step title='选择认证信息' />
            <Step title='提交认证信息' />
            <Step title='认证结果' />
          </Steps>
        </Col>
      </Row>
    )
  }

  _idDefaultDom = (img, info) => (
    <div className='card' style={{ width: '175px', height: '120px' }}>
      <img src={img} alt='id-front' />
      <span style={{ fontSize: '12px' }}>+ 点击上传身份证{info}</span>
    </div>
  )

  _idHasIdDom = img => (
    <div className='card' style={{ padding: 0, width: '175px', height: '120px' }}>
      <img src={img} alt='id-front' style={{ width: '175px', height: '120px' }} />
    </div>
  )

  changeFrontClick = async event => {
    const { verifyStore } = this.props
    const { getIdCardInfo } = verifyStore
    let base_64 = await getImgBase64(event.target.files[0])
    this.setState({
      ID_front_img: base_64
    })
    getIdCardInfo(base_64, 'front')
  }

  changeBackClick = async event => {
    const { verifyStore } = this.props
    const { getIdCardInfo } = verifyStore
    let base_64 = await getImgBase64(event.target.files[0])
    this.setState({
      ID_back_img: base_64
    })
    getIdCardInfo(base_64, 'back')
  }

  renderId = () => {
    const { verifyStore } = this.props
    const {
      id_front_img,
      id_back_img,
      id_back_info,
      id_front_info,
      isAccord,
      isInvalid,
      upFrontLoading,
      upBackLoading
    } = verifyStore
    return (
      <Row className='row-end'>
        <Col span={3} offset={2}>
          <div className='label'>身份证照片</div>
        </Col>
        <Col span={14} offset={2}>
          <div className='id-box'>
            <span>1.请提供证件的原件照片，文字、头像清晰可辨认</span>
            <span>2.格式要求jpg/png，大小不超过3M</span>
            <div>
              <input
                type={'file'}
                accept={'image/*'}
                ref={'file'}
                onChange={this.changeFrontClick}
                style={{
                  position: 'absolute',
                  top: '70px',
                  width: '175px',
                  height: '120px',
                  opacity: 0,
                  cursor: 'pointer',
                  zIndex: 999
                }}
              />
              <Spin tip='上传中...' spinning={upFrontLoading} indicator={antIcon} size={'large'}>
                {id_front_info.residentialAddress
                  ? this._idHasIdDom(this.state.ID_front_img)
                  : this._idDefaultDom(id_front_img, '正面')}
              </Spin>
            </div>
            {isAccord ? <span className='tip'>您所填的身份证信息与上传照片不符</span> : null}
            <div>
              <input
                type={'file'}
                accept={'image/*'}
                ref={'file'}
                onChange={this.changeBackClick}
                style={{
                  position: 'absolute',
                  top: '217px',
                  width: '175px',
                  height: '120px',
                  opacity: 0,
                  cursor: 'pointer',
                  zIndex: 999
                }}
              />
              <Spin tip='上传中...' spinning={upBackLoading} indicator={antIcon} size={'large'}>
                {id_back_info.issueDate
                  ? this._idHasIdDom(this.state.ID_back_img)
                  : this._idDefaultDom(id_back_img, '背面')}
              </Spin>
            </div>
            {isInvalid ? (
              <span className='tip'>
                请确认身份证有效期为：{id_back_info.issueData}-{id_back_info.invalidData}
                身份证有效期已过
              </span>
            ) : null}
          </div>
        </Col>
      </Row>
    )
  }

  renderContent = () => {
    const { verifyStore } = this.props
    const {
      nextPersonal,
      id_front_info,
      id_back_info,
      use_input_name,
      use_input_idnum,
      getUserInputName,
      getUserInputIdnum
    } = verifyStore
    return (
      <div className='content'>
        <Row>
          <Col span={3} offset={2}>
            <div className='label'>姓名</div>
          </Col>
          <Col span={14} offset={2}>
            <div className='input-box'>
              <Input
                placeholder='请输入您的姓名'
                onChange={e => {
                  getUserInputName(e.target.value)
                }}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={3} offset={2}>
            <div className='label'>身份证号</div>
          </Col>
          <Col span={14} offset={2}>
            <div className='input-box'>
              <Input
                placeholder='请输入您的身份证号'
                onChange={e => {
                  getUserInputIdnum(e.target.value)
                }}
              />
            </div>
          </Col>
        </Row>
        {this.renderId()}
        <div className='btn-box'>
          <Button
            size='large'
            onClick={() => {
              if (!use_input_name) {
                message.error('请输入您的姓名')
                return
              }
              if (!use_input_idnum) {
                message.error('请输入您的身份证号')
                return
              }
              if (!checkIdNum(use_input_idnum)) {
                message.error('身份证号格式不合法，请检查重新输入')
                return
              }
              if (!id_front_info.residentialAddress) {
                message.error('请上传身份证正面照')
                return
              }
              if (!id_back_info.authority) {
                message.error('请上传身份证背面照')
                return
              }
              if (id_front_info.name !== use_input_name) {
                message.error('所填姓名与上传照片不否')
                return
              }
              if (id_front_info.clientIDNo !== use_input_idnum) {
                message.error('所填身份证号与上传照片不否')
                return
              }
              let starttime = getFormatDate()
              let endtime = id_back_info.invalidData
              if (compareDate(starttime, endtime)) {
                message.error(`身份证有效期${endtime}已过期`)
                return
              }
              nextPersonal()
            }}
          >
            提交
          </Button>
        </div>
      </div>
    )
  }

  renderPass = () => {
    const { verifyStore } = this.props
    const { personal_verification_info } = verifyStore
    return (
      <div className='pass'>
        <img src={personal_verification_info.status === 1 ? pass : pending} alt='pass' />
        {personal_verification_info.status === 1 ? this.receiveAwards() : null}
        <p>姓名：{personal_verification_info.name}</p>
        <p>身份证号：{personal_verification_info.clientIDNo}</p>
        <div className='box'>
          <div className='card' style={{ padding: 0 }}>
            <img
              src={personal_verification_info.idCardFrontImgURL}
              alt='id-front'
              style={{ width: '210px', height: '125px' }}
            />
          </div>
          <div className='card' style={{ padding: 0 }}>
            <img
              src={personal_verification_info.idCardBackImgURL}
              alt='id-back'
              style={{ width: '210px', height: '125px' }}
            />
          </div>
        </div>
      </div>
    )
  }

  selectOption = e => {
    this.setState({
      index: e
    })
  }

  receiveAwards = () => {
    const { index } = this.state
    const { userStore } = this.props
    const { cardList, receiveAwards, userInfo } = userStore
    return (
      <div className='receive-awards-wrp'>
        <div className='left-title'>选择领取地址</div>
        <div className='right-con'>
          <div className='name'>{cardList.length ? cardList[index].nickname : ''}</div>
          <div className='address'>{cardList[index].addressString}</div>
          <Select
            style={{ width: 90 }}
            placeholder={cardList.length > 0 ? cardList[index].nickname : ''}
            suffixIcon={<Icon type='more' />}
            onChange={this.selectOption}
          >
            {cardList.map((item, index) => {
              return (
                <Option value={index} key={item.id}>
                  {item.nickname ? item.nickname : ''}
                </Option>
              )
            })}
          </Select>
        </div>
        <Button
          className='awards-number'
          type='primary'
          disabled={userInfo.awardsNumber === 0 ? true : false}
          onClick={() => {
            receiveAwards(cardList[index].addressString)
          }}
        >
          领取奖励：剩余{userInfo.awardsNumber}次
        </Button>
      </div>
    )
  }

  render() {
    const { verifyStore } = this.props
    const { current_personal } = verifyStore
    return (
      <div className='verify'>
        <Card>
          <h1>个人用户实名认证</h1>
          {this.renderSteps()}
          {current_personal === 1 && this.renderContent()}
          {current_personal === 2 && this.renderPass()}
        </Card>
      </div>
    )
  }
}

export default Personal
