import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import { Row, Col, Card, Input, Button, message/* , Select */ } from 'antd'
import { checkPhoneNum } from '../../../common/tool'
// eslint-disable-next-line
import style from './index.less'

import logo from '../../../images/logo.png'
import { areaCode } from '../../../config'

/* const { Option } = Select */

@inject('userStore')
@observer
class Login extends Component {
  constructor(props) {
    super(props)
    this.state = {
      disabled: false,
      count: 0,
      param_ITC: '86',
      param_phone: '',
      param_code: '',
    }
  }

  componentDidMount() {
    if (localStorage.token) {
      this.props.history.replace('/assets/home')
    }
  }

  getCode = async () => {
    const { userStore } = this.props
    const { sendVerificationCode } = userStore
    const { /* param_ITC, */ param_phone } = this.state
    if (!param_phone) {
      message.error('请输入手机号')
      return
    }
    if (!checkPhoneNum(param_phone)) {
      message.error('手机号格式不合法，请检查重新输入')
      return
    }
    /* await sendVerificationCode(param_ITC, param_phone) */
    await sendVerificationCode(param_phone)
    if (userStore.param_code_status) {
      this.setState({
        disabled: true,
        count: 60,
      })
      this.startCount()
    } else {
      message.error('无法获取验证码')
    }
  }

  startCount = () => {
    let counter = setInterval(() => {
      if (this.state.count === 0) {
        clearInterval(counter)
        this.setState({
          disabled: false,
        })
        return
      }
      this.setState({
        count: this.state.count - 1,
      })
    }, 1000)
  }

  setParamAreaCode = (value = 0) => {
    this.setState({
      param_ITC: areaCode[value].code,
    })
  }

  setParamPhone = e => {
    this.setState({
      param_phone: e.target.value,
    })
  }

  setParamCode = e => {
    this.setState({
      param_code: e.target.value,
    })
  }

  check = () => {
    if (!this.state.param_phone) {
      message.error('请输入手机号')
      return
    }
    if (!checkPhoneNum(this.state.param_phone)) {
      message.error('手机号格式不合法，请检查重新输入')
      return
    }
    if (!this.state.param_code) {
      message.error('请输入短信验证码')
      return
    }
    this.login()
  }

  login = async () => {
    const { userStore } = this.props
    const { /* param_ITC, */ param_phone, param_code } = this.state
    /* let res = await userStore.login(param_ITC, param_phone, param_code) */
    let res = await userStore.login(param_phone, param_code)
    if (res) {
      this.setDefault()
    }
  }

  setDefault = async () => {
    const { getUserInfo, getCardList } = this.props.userStore
    await getUserInfo()
    let hasCard = await getCardList()
    if (!hasCard) {
      message.warning('请到APP先完成创建账户')
      this.props.history.push('/process')
      return
    } else {
      this.props.history.push('/assets/home')
    }
  }

  render() {
    const { userStore } = this.props
    const { loading } = userStore
    const { disabled, count } = this.state
    return (
      <div className="login">
        <Row>
          <Col span={4} />
          <Col span={16}>
            <Card>
              <Row>
                <Col span={8} push={8}>
                  <img className="logo" src={logo} alt="" />
                </Col>
              </Row>
              <Row className="item">
                <Col span={4} offset={4}>
                  <div className="label">手机号</div>
                </Col>
                <Col className="input-box" span={12}>
                  <Input
                    placeholder="请输入手机号"
                    allowClear
                    onChange={this.setParamPhone}
                    /* addonBefore={
                      <Select
                        defaultValue={0}
                        dropdownMatchSelectWidth={false}
                        optionLabelProp="code"
                        onSelect={this.setParamAreaCode}
                        style={{
                          width: 70,
                        }}
                        dropdownMenuStyle={{
                          maxHeight: 'none',
                          height: 500,
                          width: 250,
                        }}
                      >
                        {areaCode.map((item, index) => (
                          <Option
                            key={index}
                            value={index}
                            code={`+${item.code}`}
                          >{`${item.flag} ${item.area} +${item.code}`}</Option>
                        ))}
                      </Select>
                    } */
                  />
                  <Button
                    size="small"
                    onClick={this.getCode}
                    disabled={disabled}
                  >
                    {!disabled && '获取验证码'}
                    {disabled && `${count}秒`}
                  </Button>
                </Col>
              </Row>
              <Row className="item">
                <Col span={4} offset={4}>
                  <div className="label">短信验证码</div>
                </Col>
                <Col className="input-box" span={12}>
                  <Input
                    placeholder="请输入4位验证码"
                    allowClear
                    onChange={this.setParamCode}
                  />
                </Col>
              </Row>
              <Row className="submit">
                <Button loading={loading} onClick={this.check}>
                  登录
                </Button>
              </Row>
            </Card>
          </Col>
          <Col span={2} />
        </Row>
      </div>
    )
  }
}

export default Login
