import React, { Component } from 'react'
import './index.less'

import bgImg from '../../../images/app_download.png'

class Download extends Component {
  render() {
    return (
      <div className='download-wrp'>
        <img src={bgImg} alt='download_qrcode' />
      </div>
    )
  }
}

export default Download
